import tekstid from "./tekstid"
import { Link } from "react-router-dom";

function Button({keel}) {

    
    return (
        <Link to="/contact" className="bg-orange font-sans w-[60%] h-[20%] rounded-full text-valge text-4xl drop-shadow-lg border-2 flex items-center justify-center" style={{marginLeft:"25%",marginBottom:"20%"}}>
        {tekstid("cta", keel)}
      </Link>
        
        
    )
}

export default Button


//<button className="bg-orange font-sans w-[60%] h-[20%] rounded-full text-valge text-4xl drop-shadow-lg border-2" style={{marginLeft:"25%",marginBottom:"20%"}}>{tekstid("cta", keel)}</button>