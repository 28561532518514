import Lorem from "./Lorem";
import n2gu2 from "./pildid/n2gu2.png";
import Button from "./Button";

const Home = ({ keel }) => {
  return (
    <>
      {" "}
      <div className="border-must border-y-2 h-[43%] flex ">
        <div className="flex justify-start w-1/2 items-end ">
          <Button keel={keel} />
        </div>
        <div className="flex justify-end items-center w-1/2">
          <img
            src={n2gu2}
            className="h-5/6 object-right"
            style={{ marginRight: "4%" }}
            alt="n2gu2"
          ></img>
        </div>
      </div>
      <div className="h-[50%] bg-[white]  rounded-b-lg">
        <Lorem />
      </div>
    </>
  );
};

export default Home;
