/* eslint-disable no-unused-vars */
import Button from "./Button";
import { Link } from "react-router-dom";
import logo from "./pildid/logo.png";
import ee from "./pildid/ee.svg";
import gb from "./pildid/gb.svg";
import tekstid from "./tekstid";

function Navbar({ stiil, setStiil, keel, setKeel }) {
  function stiilimuutus(event) {
    const taevas = {
      nimi: "taevas",
      taust: "bg-sky",
      varv1: "bg-valge",
    };
    const purple = {
      nimi: "purpur",
      taust: "bg-purple",
      varv1: "bg-valge",
    };
    const nupuId = event.target.id;
    let nahk = "";
    if (nupuId === "taevas" && stiil !== "taevas") {
      nahk = taevas;
    }
    if (nupuId === "purple" && stiil !== "purple") {
      nahk = purple;
    }
    setStiil(nahk);
  }

  function keelemuutus(uuskeel) {
    setKeel(uuskeel);
  }

  return (
    <header className="bg-[white] h-[7%] rounded-t-lg flex items-center justify-end">
      <div className="h-full w-[15%] flex items-center justify-start">
        <img
          src={logo}
          className="h-[70%] hover:rotate-12 hover:scale-50 animate-keerleb px-1"
          style={{ marginLeft: "1%" }}
          alt="logo"
        />
        <div className="flex w-full h-full justify-start flex-col m-2">
          <button
            className="w-1/3 h-1/3 m-0.5 bg-sky border-gray-dark border-2 rounded-md"
            id="taevas"
            onClick={stiilimuutus}
          ></button>
          <button
            className="w-1/3 h-1/3 m-0.5 bg-purple border-gray-dark border-2 rounded-md"
            id="purple"
            onClick={stiilimuutus}
          ></button>
          <button className="w-1/3 h-1/3 m-0.5 bg-gray border-gray-dark border-2 rounded-md"></button>
        </div>
      </div>
      <div className="w-[35%]"></div>
      <div className="w-[45%] flex items-center justify-end">
        <Link to="/" className="px-4 text-center text-2xl font-sans font-bold hover:text-3xl">
          {tekstid("navbar1", keel)}
        </Link>
        <Link to="/Ruuter" className="px-4 text-center text-2xl font-sans font-bold hover:text-3xl">
          {tekstid("navbar2", keel)}
        </Link>
        <Link to="/Test" className="px-4 text-center text-2xl font-sans font-bold hover:text-3xl">
          {tekstid("navbar3", keel)}
        </Link>
        <Link to="/Mang" className="px-4 text-center text-2xl font-sans font-bold hover:text-3xl">
          {tekstid("navbar4", keel)}
        </Link>
        <Link to="/contact" className="px-4 text-center text-2xl font-sans font-bold hover:text-3xl">
          {tekstid("navbar5", keel)}
        </Link>
      </div>
      <div className="w-[5%] h-full flex flex-col items-end">
        <button className="h-1/4 m-0.5 border-2" id="est" onClick={() => keelemuutus("est")}>
          <img className="h-full" src={ee} alt="est" />
        </button>
        <button className="h-1/4 m-0.5 border-2" id="eng" onClick={() => keelemuutus("eng")}>
          <img className="h-full" src={gb} alt="eng" />
        </button>
      </div>
    </header>
  );
}

export default Navbar;
