import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Ruuter from "./Ruuter";
import NoPage from "./NoPage";
import Mang from "./Mang";
import Test from "./Test";


function Keskosa({ mangustate, setMangustate, stiil, setStiil, keel, setKeel }) {
  return (
    <div className="w-[1000px] h-90vh border-must border-2 rounded-lg">
      <Navbar stiil={stiil} setStiil={setStiil} keel={keel} setKeel={setKeel} />

        <Routes>
          <Route index element={<Home keel={keel}/>} />
          <Route
            path="Test"
            element={<Test />}
          />
          <Route path="Mang" element={<Mang keel={keel} mangustate={mangustate} setMangustate={setMangustate} />}/>
          <Route path="*" element={<NoPage />} />
        </Routes>{" "}

    </div>
  );
}

export default Keskosa;
