function tekstid(element, keel) {
  const el = element;
  const eesti = {
    navbar1: "Minust",
    navbar2: "Blogi",
    navbar3: "Tööd",
    navbar4: "Mäng",
    navbar5: "Kontakt",
    cta:"Võta ühendust",
  };
  const inglise = {
    navbar1: "Home",
    navbar2: "Blog",
    navbar3: "Portfolio",
    navbar4: "Play",
    navbar5: "Content",
    cta:"Contact me",
  };

  if (keel === "est") {
    return eesti[el];
  }
  if (keel === "eng") {
    return inglise[el];
  }
}

export default tekstid;
