import React, { useRef, useEffect, useState } from "react";
import image1 from "./pildid/image.png";

function MyCanvasComponent() {
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [imageloaded, setImageloaded] = useState(false);

  useEffect(() => {
    imageRef.current = new Image();
    imageRef.current.onload = () => setImageloaded(true);
    imageRef.current.src = image1;
  }, []);

  function draw(image, x) {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvasRef.current.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, x, 0, image.width, image.height);
    }
  }

  let counter = 1;
  setInterval(() => {
    if (imageloaded) {
      if (counter % 2 === 0) {
        draw(imageRef.current, 200);
      } else draw(imageRef.current, 100);
      counter++;
    }
  }, 1000);
  
  return (
    <div>
      <canvas ref={canvasRef} width={300} height={200} />
    </div>
  );
}

function App() {
  return (
    <div>
      <h1>My Canvas App</h1>
      <MyCanvasComponent />
    </div>
  );
}

export default App;
