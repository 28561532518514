function Kaart({ nimi, pilt, onClick, maks, mana }) {
  return (
    <button className={`w-[140px] h-[200px] rounded-lg border-2 border-must flex flex-col items-center ${maks > mana ? "bg-gray" : "bg-green"}`}>
      {nimi}
      {<img src={pilt} className="w-[80%]" alt=" " onClick={onClick}></img>}
    </button>
  );
}

export default Kaart;
