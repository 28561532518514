import "./input.css";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";
import Keskosa from "./Keskosa";

function App() {
  //const getInitialDrawings = () => {
  //  const savedDrawings = localStorage.getItem("savedDrawings");
  //  if (savedDrawings) {
  //    return JSON.parse(savedDrawings);
  //  }
  //  return [];
  //};
  //const [drawings, setDrawings] = useState(getInitialDrawings);
  const [mangustate,setMangustate] = useState()
  
  const [stiil, setStiil] = useState({
    nimi: "taevas",
    taust: "bg-sky",
    varv1: "bg-valge",
  });
  const [keel, setKeel] = useState("est");
  return (
    <BrowserRouter>
      <div
        className={`${stiil.taust} h-screen w-screen flex justify-center items-center`}
      >
        <Keskosa
          stiil={stiil}
          setStiil={setStiil}
          keel={keel}
          setKeel={setKeel}
          mangustate={mangustate}
          setMangustate={setMangustate}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
