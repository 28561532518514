import React, { useRef, useEffect, } from 'react';

function Ruuter ({drawings,setDrawings}) {
    const canvasRef = useRef(null);


    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);

        // Redraw the canvas based on stored drawings
        drawings.forEach(drawing => {
            context.fillStyle = drawing.color;
            context.fillRect(drawing.x, drawing.y, drawing.width, drawing.height);
        });
    }, [drawings]); // Redraw when drawings state changes

    const drawOnCanvas = () => {
        const newDrawing = {
            color: 'black',
            x: 10, // Example position and size
            y: 10,
            width: 50,
            height: 50,
        };

        setDrawings([...drawings, newDrawing]); // Add new drawing to state
        localStorage.setItem('savedDrawings', JSON.stringify(drawings));
        console.log(drawings);
        console.log(localStorage.getItem('savedDrawings'));
    };

    return (

        <div>
            <canvas ref={canvasRef} width="200" height="100" style={{ border: '1px solid #000' }}></canvas>
            <button onClick={drawOnCanvas}>Draw Square</button>
        </div>
    );
}

  
  export default Ruuter;
  