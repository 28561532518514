function Lorem() {
  return (
    <p className="w-full">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida
      augue id odio bibendum eleifend. Sed finibus lorem ac ligula venenatis, et
      congue mi rhoncus. Maecenas at quam dictum purus lobortis feugiat ut id
      lorem. Ut porttitor lorem sit amet lacus hendrerit imperdiet. Mauris metus
      nunc, faucibus ut malesuada ut, sodales vel risus. Maecenas dapibus tortor
      at mi posuere, semper tincidunt enim viverra. Nunc molestie mollis eros,
      vel suscipit massa facilisis sit amet. Phasellus sit amet convallis eros.
      Nulla eu lorem finibus, dapibus quam sed, sagittis erat. Maecenas id quam
      ut metus venenatis convallis. Pellentesque sagittis risus eget arcu
      condimentum laoreet. Aliquam feugiat quam non dui elementum aliquet id id
      neque. Sed ultrices lobortis lectus, sit amet faucibus eros tempus non.
      Praesent a neque vel augue imperdiet facilisis vitae id massa. Fusce ut
      hendrerit est. Nunc congue tincidunt pretium. Etiam viverra libero odio,
      ac efficitur lacus blandit ac. Suspendisse sit amet ante euismod, feugiat
      libero quis, euismod sem. Nunc rhoncus eleifend leo, sit amet tincidunt
      lorem sollicitudin in. Ut ornare faucibus erat, nec auctor erat efficitur
      a. {/* Fusce posuere, purus eget ornare dignissim, ex urna venenatis risus, ut
       semper ipsum metus sit amet ex. Aliquam quis lacus malesuada nisi
      tincidunt rutrum in non quam. Duis lorem mauris, euismod id nulla vitae,
      tempor vehicula purus. Mauris ut metus a dolor imperdiet imperdiet non sed
      tortor. Nunc sagittis, augue at blandit iaculis, purus nisi pulvinar nisl,
      quis gravida tortor lorem ac ligula. Vestibulum ac faucibus turpis, eget
      posuere nisi. Nunc mollis nunc ac vestibulum rutrum. Praesent vitae ante
      quis eros sollicitudin iaculis eget vitae tellus. Ut ornare erat purus, id
      tristique purus sagittis eget. Nunc vel ipsum augue. Aliquam et tortor nec
      lorem posuere mattis. Curabitur convallis vestibulum enim, id mattis velit
      gravida ut. Nunc condimentum, urna sed semper sagittis, mauris orci
      rhoncus est, eu ornare lacus libero nec mi. Vivamus feugiat velit quis
      dolor sollicitudin euismod. Suspendisse eu augue vestibulum, finibus odio
      sit amet, auctor nunc. Pellentesque vitae massa felis. Nullam scelerisque
      est est, nec feugiat metus maximus id. Morbi non accumsan arcu. Praesent
      condimentum, tellus in mollis fringilla, risus erat hendrerit risus, ac
      iaculis orci nibh sit amet nunc. Cras non dolor pulvinar, pulvinar lorem
      ut, eleifend arcu. Pellentesque porttitor vitae libero ac mollis. Ut
      tincidunt nisl tempor tellus dictum sollicitudin. Proin placerat sapien
      sed bibendum condimentum. Mauris ut neque venenatis, convallis nibh in,
      mollis erat. Aenean interdum lorem sed tincidunt suscipit. Aenean quis
      imperdiet nisl. Ut accumsan sed tortor a lobortis. Suspendisse iaculis
      tellus non mi rhoncus rutrum. Vestibulum maximus diam felis, eu laoreet
      sem porttitor ac. Donec ultrices tincidunt blandit. Nam hendrerit leo id
      diam sodales, vel egestas ex porta. Pellentesque at massa efficitur libero
      accumsan laoreet a in leo. Donec eleifend vitae magna nec pellentesque.
      Sed consectetur tellus nec diam euismod maximus. Sed sed pellentesque leo.
      Mauris neque velit, euismod ac velit ut, commodo euismod magna. Curabitur
      id lectus iaculis, pulvinar mi ut, ultricies quam. Nulla eleifend eget
      risus nec luctus. Praesent et commodo nisl. Nam efficitur euismod tortor,
      vel malesuada eros sagittis in. Fusce sagittis pharetra commodo. Integer
      posuere semper venenatis. Vestibulum venenatis malesuada fringilla. Nullam
      vel ante ut mi convallis aliquam. Pellentesque et nulla pharetra,
      convallis arcu accumsan, efficitur tellus. Nunc facilisis erat justo, eu
      consequat neque condimentum non. Suspendisse vel dapibus nulla, sed
      aliquam sem. Praesent volutpat eleifend libero sit amet tempus. Vivamus et
      accumsan lorem. Maecenas feugiat, libero nec bibendum consectetur, neque
      odio tristique massa, at posuere lacus libero vitae tellus. In non euismod
      enim. Duis euismod molestie metus. Nam eget ornare massa. Duis vulputate
      sem ut accumsan dapibus. Morbi laoreet augue id enim convallis, ac
      suscipit elit vestibulum. Proin at posuere est. Curabitur id justo
      posuere, posuere mi eget, auctor justo. Nullam pulvinar euismod felis non
      cursus. Curabitur ut tincidunt leo. Nullam posuere ipsum a mauris feugiat
      volutpat. Praesent lorem nulla, fringilla eget ullamcorper vitae,
      venenatis id libero. Fusce sit amet eros orci. Curabitur nisl metus,
      tincidunt sit amet sapien sit amet, feugiat tincidunt lacus. Morbi neque
      felis, consectetur at convallis non, porttitor ut magna. Nulla facilisi.
      Curabitur lacinia ultrices magna luctus fringilla. Sed ac gravida est. Sed
      varius pretium massa sit amet dignissim. Morbi lacinia orci id augue
      pharetra, non suscipit ipsum gravida. Vivamus vitae malesuada ligula, nec
      convallis nisi. Sed dapibus mauris sit amet lacus ornare, non viverra
      sapien blandit. Maecenas ultricies fringilla lacus, non vestibulum sapien
      condimentum id. Nullam consectetur molestie mauris, ut dapibus risus
      lacinia eu. Pellentesque at dui non nunc ullamcorper porta at vitae ipsum.
      Nulla mollis condimentum purus, non molestie nisl. Ut pulvinar, purus non
      venenatis gravida, tortor mi congue est, non tempus urna ante eu nunc.
      Nullam pellentesque quam quis augue faucibus gravida. Nulla tincidunt
      vehicula ligula, vel ultrices eros volutpat eu. Mauris dapibus, turpis sed
      commodo dapibus, lorem ex bibendum ex, eu varius lorem mi semper dui. Sed
      ornare posuere nisi in suscipit.Vestibulum sed rhoncus odio. Sed non
      interdum diam. Pellentesque hendrerit dui sem, at fringilla odio fermentum
      a. Nunc ut tortor nunc. Pellentesque convallis quam quis finibus
      consequat. Etiam ullamcorper luctus quam, nec blandit erat laoreet vitae.
      Integer quis sem et turpis consequat semper eget vitae urna. Mauris a
      lectus aliquet, tincidunt mi sed, pharetra tellus. Suspendisse vulputate
      enim vel justo convallis, eget sodales elit fringilla. In sit amet tempus
      nulla. Nam nec quam venenatis, luctus tortor sit amet, euismod lectus. In
      semper metus vel enim commodo, quis tincidunt velit faucibus. Duis ipsum
      turpis, fermentum euismod interdum at, ullamcorper ut odio. Mauris in
      purus dictum, egestas dolor ac, dignissim odio. Morbi bibendum interdum
      sem, et suscipit lacus facilisis at. Nulla suscipit eros ultrices nibh
      tempus, nec blandit arcu posuere. Duis rutrum nunc felis, dapibus
      efficitur velit accumsan vel. Duis ultricies velit ac urna ultricies
      interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida
      augue id odio bibendum eleifend. Sed finibus lorem ac ligula venenatis, et
      congue mi rhoncus. Maecenas at quam dictum purus lobortis feugiat ut id
      lorem. Ut porttitor lorem sit amet lacus hendrerit imperdiet. Mauris metus
      nunc, faucibus ut malesuada ut, sodales vel risus. Maecenas dapibus tortor
      at mi posuere, semper tincidunt enim viverra. Nunc molestie mollis eros,
      vel suscipit massa facilisis sit amet. Phasellus sit amet convallis eros.
      Nulla eu lorem finibus, dapibus quam sed, sagittis erat. Maecenas id quam
      ut metus venenatis convallis. Pellentesque sagittis risus eget arcu
      condimentum laoreet. Aliquam feugiat quam non dui elementum aliquet id id
      neque. Sed ultrices lobortis lectus, sit amet faucibus eros tempus non.
      Praesent a neque vel augue imperdiet facilisis vitae id massa. Fusce ut
      hendrerit est. Nunc congue tincidunt pretium. Etiam viverra libero odio,
      ac efficitur lacus blandit ac. Suspendisse sit amet ante euismod, feugiat
      libero quis, euismod sem. Nunc rhoncus eleifend leo, sit amet tincidunt
      lorem sollicitudin in. Ut ornare faucibus erat, nec auctor erat efficitur
      a. Fusce posuere, purus eget ornare dignissim, ex urna venenatis risus, ut
      semper ipsum metus sit amet ex. Aliquam quis lacus malesuada nisi
      tincidunt rutrum in non quam. Duis lorem mauris, euismod id nulla vitae,
      tempor vehicula purus. Mauris ut metus a dolor imperdiet imperdiet non sed
      tortor. Nunc sagittis, augue at blandit iaculis, purus nisi pulvinar nisl,
      quis gravida tortor lorem ac ligula. Vestibulum ac faucibus turpis, eget
      posuere nisi. Nunc mollis nunc ac vestibulum rutrum. Praesent vitae ante
      quis eros sollicitudin iaculis eget vitae tellus. Ut ornare erat purus, id
      tristique purus sagittis eget. Nunc vel ipsum augue. Aliquam et tortor nec
      lorem posuere mattis. Curabitur convallis vestibulum enim, id mattis velit
      gravida ut. Nunc condimentum, urna sed semper sagittis, mauris orci
      rhoncus est, eu ornare lacus libero nec mi. Vivamus feugiat velit quis
      dolor sollicitudin euismod. Suspendisse eu augue vestibulum, finibus odio
      sit amet, auctor nunc. Pellentesque vitae massa felis. Nullam scelerisque
      est est, nec feugiat metus maximus id. Morbi non accumsan arcu. Praesent
      condimentum, tellus in mollis fringilla, risus erat hendrerit risus, ac
      iaculis orci nibh sit amet nunc. Cras non dolor pulvinar, pulvinar lorem
      ut, eleifend arcu. Pellentesque porttitor vitae libero ac mollis. Ut
      tincidunt nisl tempor tellus dictum sollicitudin. Proin placerat sapien
      sed bibendum condimentum. Mauris ut neque venenatis, convallis nibh in,
      mollis erat. Aenean interdum lorem sed tincidunt suscipit. Aenean quis
      imperdiet nisl. Ut accumsan sed tortor a lobortis. Suspendisse iaculis
      tellus non mi rhoncus rutrum. Vestibulum maximus diam felis, eu laoreet
      sem porttitor ac. Donec ultrices tincidunt blandit. Nam hendrerit leo id
      diam sodales, vel egestas ex porta. Pellentesque at massa efficitur libero
      accumsan laoreet a in leo. Donec eleifend vitae magna nec pellentesque.
      Sed consectetur tellus nec diam euismod maximus. Sed sed pellentesque leo.
      Mauris neque velit, euismod ac velit ut, commodo euismod magna. Curabitur
      id lectus iaculis, pulvinar mi ut, ultricies quam. Nulla eleifend eget
      risus nec luctus. Praesent et commodo nisl. Nam efficitur euismod tortor,
      vel malesuada eros sagittis in. Fusce sagittis pharetra commodo. Integer
      posuere semper venenatis. Vestibulum venenatis malesuada fringilla. Nullam
      vel ante ut mi convallis aliquam. Pellentesque et nulla pharetra,
      convallis arcu accumsan, efficitur tellus. Nunc facilisis erat justo, eu
      consequat neque condimentum non. Suspendisse vel dapibus nulla, sed
      aliquam sem. Praesent volutpat eleifend libero sit amet tempus. Vivamus et
      accumsan lorem. Maecenas feugiat, libero nec bibendum consectetur, neque
      odio tristique massa, at posuere lacus libero vitae tellus. In non euismod
      enim. Duis euismod molestie metus. Nam eget ornare massa. Duis vulputate
      sem ut accumsan dapibus. Morbi laoreet augue id enim convallis, ac
      suscipit elit vestibulum. Proin at posuere est. Curabitur id justo
      posuere, posuere mi eget, auctor justo. Nullam pulvinar euismod felis non
      cursus. Curabitur ut tincidunt leo. Nullam posuere ipsum a mauris feugiat
      volutpat. Praesent lorem nulla, fringilla eget ullamcorper vitae,
      venenatis id libero. Fusce sit amet eros orci. Curabitur nisl metus,
      tincidunt sit amet sapien sit amet, feugiat tincidunt lacus. Morbi neque
      felis, consectetur at convallis non, porttitor ut magna. Nulla facilisi.
      Curabitur lacinia ultrices magna luctus fringilla. Sed ac gravida est. Sed
      varius pretium massa sit amet dignissim. Morbi lacinia orci id augue
      pharetra, non suscipit ipsum gravida. Vivamus vitae malesuada ligula, nec
      convallis nisi. Sed dapibus mauris sit amet lacus ornare, non viverra
      sapien blandit. Maecenas ultricies fringilla lacus, non vestibulum sapien
      condimentum id. Nullam consectetur molestie mauris, ut dapibus risus
      lacinia eu. Pellentesque at dui non nunc ullamcorper porta at vitae ipsum.
      Nulla mollis condimentum purus, non molestie nisl. Ut pulvinar, purus non
      venenatis gravida, tortor mi congue est, non tempus urna ante eu nunc.
      Nullam pellentesque quam quis augue faucibus gravida. Nulla tincidunt
      vehicula ligula, vel ultrices eros volutpat eu. Mauris dapibus, turpis sed
      commodo dapibus, lorem ex bibendum ex, eu varius lorem mi semper dui. Sed
      ornare posuere nisi in suscipit. Vestibulum sed rhoncus odio. Sed non
      interdum diam. Pellentesque hendrerit dui sem, at fringilla odio fermentum
      a. Nunc ut tortor nunc. Pellentesque convallis quam quis finibus
      consequat. Etiam ullamcorper luctus quam, nec blandit erat laoreet vitae.
      Integer quis sem et turpis consequat semper eget vitae urna. Mauris a
      lectus aliquet, tincidunt mi sed, pharetra tellus. Suspendisse vulputate
      enim vel justo convallis, eget sodales elit fringilla. In sit amet tempus
      nulla. Nam nec quam venenatis, luctus tortor sit amet, euismod lectus. In
      semper metus vel enim commodo, quis tincidunt velit faucibus. Duis ipsum
      turpis, fermentum euismod interdum at, ullamcorper ut odio. Mauris in
      purus dictum, egestas dolor ac, dignissim odio. Morbi bibendum interdum
      sem, et suscipit lacus facilisis at. Nulla suscipit eros ultrices nibh
      tempus, nec blandit arcu posuere. Duis rutrum nunc felis, dapibus
      efficitur velit accumsan vel. Duis ultricies velit ac urna ultricies
      interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur gravida
      augue id odio bibendum eleifend. Sed finibus lorem ac ligula venenatis, et
      congue mi rhoncus. Maecenas at quam dictum purus lobortis feugiat ut id
      lorem. Ut porttitor lorem sit amet lacus hendrerit imperdiet. Mauris metus
      nunc, faucibus ut malesuada ut, sodales vel risus. Maecenas dapibus tortor
      at mi posuere, semper tincidunt enim viverra. Nunc molestie mollis eros,
      vel suscipit massa facilisis sit amet. Phasellus sit amet convallis eros.
      Nulla eu lorem finibus, dapibus quam sed, sagittis erat. Maecenas id quam
      ut metus venenatis convallis. Pellentesque sagittis risus eget arcu
      condimentum laoreet. Aliquam feugiat quam non dui elementum aliquet id id
      neque. Sed ultrices lobortis lectus, sit amet faucibus eros tempus non.
      Praesent a neque vel augue imperdiet facilisis vitae id massa. Fusce ut
      hendrerit est. Nunc congue tincidunt pretium. Etiam viverra libero odio,
      ac efficitur lacus blandit ac. Suspendisse sit amet ante euismod, feugiat
      libero quis, euismod sem. Nunc rhoncus eleifend leo, sit amet tincidunt
      lorem sollicitudin in. Ut ornare faucibus erat, nec auctor erat efficitur
      a. Fusce posuere, purus eget ornare dignissim, ex urna venenatis risus, ut
      semper ipsum metus sit amet ex. Aliquam quis lacus malesuada nisi
      tincidunt rutrum in non quam. Duis lorem mauris, euismod id nulla vitae,
      tempor vehicula purus. Mauris ut metus a dolor imperdiet imperdiet non sed
      tortor. Nunc sagittis, augue at blandit iaculis, purus nisi pulvinar nisl,
      quis gravida tortor lorem ac ligula. Vestibulum ac faucibus turpis, eget
      posuere nisi. Nunc mollis nunc ac vestibulum rutrum. Praesent vitae ante
      quis eros sollicitudin iaculis eget vitae tellus. Ut ornare erat purus, id
      tristique purus sagittis eget. Nunc vel ipsum augue. Aliquam et tortor nec
      lorem posuere mattis. Curabitur convallis vestibulum enim, id mattis velit
      gravida ut. Nunc condimentum, urna sed semper sagittis, mauris orci
      rhoncus est, eu ornare lacus libero nec mi. Vivamus feugiat velit quis
      dolor sollicitudin euismod. Suspendisse eu augue vestibulum, finibus odio
      sit amet, auctor nunc. Pellentesque vitae massa felis. Nullam scelerisque
      est est, nec feugiat metus maximus id. Morbi non accumsan arcu. Praesent
      condimentum, tellus in mollis fringilla, risus erat hendrerit risus, ac
      iaculis orci nibh sit amet nunc. Cras non dolor pulvinar, pulvinar lorem
      ut, eleifend arcu. Pellentesque porttitor vitae libero ac mollis. Ut
      tincidunt nisl tempor tellus dictum sollicitudin. Proin placerat sapien
      sed bibendum condimentum. Mauris ut neque venenatis, convallis nibh in,
      mollis erat. Aenean interdum lorem sed tincidunt suscipit. Aenean quis
      imperdiet nisl. Ut accumsan sed tortor a lobortis. Suspendisse iaculis
      tellus non mi rhoncus rutrum. Vestibulum maximus diam felis, eu laoreet
      sem porttitor ac. Donec ultrices tincidunt blandit. Nam hendrerit leo id
      diam sodales, vel egestas ex porta. Pellentesque at massa efficitur libero
      accumsan laoreet a in leo. Donec eleifend vitae magna nec pellentesque.
      Sed consectetur tellus nec diam euismod maximus. Sed sed pellentesque leo.
      Mauris neque velit, euismod ac velit ut, commodo euismod magna. Curabitur
      id lectus iaculis, pulvinar mi ut, ultricies quam. Nulla eleifend eget
      risus nec luctus. Praesent et commodo nisl. Nam efficitur euismod tortor,
      vel malesuada eros sagittis in. Fusce sagittis pharetra commodo. Integer
      posuere semper venenatis. Vestibulum venenatis malesuada fringilla. Nullam
      vel ante ut mi convallis aliquam. Pellentesque et nulla pharetra,
      convallis arcu accumsan, efficitur tellus. Nunc facilisis erat justo, eu
      consequat neque condimentum non. Suspendisse vel dapibus nulla, sed
      aliquam sem. Praesent volutpat eleifend libero sit amet tempus. Vivamus et
      accumsan lorem. Maecenas feugiat, libero nec bibendum consectetur, neque
      odio tristique massa, at posuere lacus libero vitae tellus. In non euismod
      enim. Duis euismod molestie metus. Nam eget ornare massa. Duis vulputate
      sem ut accumsan dapibus. Morbi laoreet augue id enim convallis, ac
      suscipit elit vestibulum. Proin at posuere est. Curabitur id justo
      posuere, posuere mi eget, auctor justo. Nullam pulvinar euismod felis non
      cursus. Curabitur ut tincidunt leo. Nullam posuere ipsum a mauris feugiat
      volutpat. Praesent lorem nulla, fringilla eget ullamcorper vitae,
      venenatis id libero. Fusce sit amet eros orci. Curabitur nisl metus,
      tincidunt sit amet sapien sit amet, feugiat tincidunt lacus. Morbi neque
      felis, consectetur at convallis non, porttitor ut magna. Nulla facilisi.
      Curabitur lacinia ultrices magna luctus fringilla. Sed ac gravida est. Sed
      varius pretium massa sit amet dignissim. Morbi lacinia orci id augue
      pharetra, non suscipit ipsum gravida. Vivamus vitae malesuada ligula, nec
      convallis nisi. */}
    </p>
  );
}

export default Lorem