import { useRef, useEffect, useState } from "react";
import emalaev from "./pildid/emalaev.png";
import Kaart from "./Kaart";
import Testnupp from "./Testnupp";
import kosmos from "./pildid/kosmos.png";
import spraidid from "./pildid/spraidid.png";
let taanduda = false;
let edasiliikuda = false;
let rynnata = false;
let v_taanduda = false;
let v_edasiliikuda = false;
let v_rynnata = false;
let vaba = true;
let anima = [0, 0];
let animeerida = {
  m_laevad: [],
  v_laevad: [],
  m_alguspunkt: 0,
  v_alguspunkt: 0,
  m_jarel: 0,
  v_jarel: 0,
};
const k = {
  c_laius: 960,
  c_korgus: 400,
  emalaevalaius: 114,
  emalaevakorgus: 200,
  minuemalaevx: 0,
  get vastaseemalaevx() {
    return this.c_laius - this.emalaevalaius;
  },
  get emalaevy() {
    return (this.c_korgus - this.emalaevakorgus) / 2;
  },
  ruut: 50,
  get poolruut() {
    return this.ruut / 2;
  },
  vahe: 4,
  get poolvahe() {
    return this.vahe / 2;
  },
  get pool() {
    return this.c_laius / 2;
  },
  get vertpool() {
    return this.c_korgus / 2;
  },
  get xkaitse() {
    return this.emalaevalaius + this.vahe;
  },
  get v_xkaitse() {
    return this.c_laius - this.emalaevalaius - this.vahe - this.ruut;
  },
  get xlaev1() {
    return this.xkaitse + this.ruut + this.vahe;
  },
  get v_xlaev1() {
    return this.v_xkaitse - this.vahe - this.ruut;
  },
  get xlaev2() {
    return this.xlaev1 + this.ruut + this.vahe;
  },
  get v_xlaev2() {
    return this.v_xlaev1 - this.vahe - this.ruut;
  },
  get baasiees1() {
    return this.v_xlaev2 - this.vahe - this.ruut;
  },
  get v_baasiees1() {
    return this.xlaev2 + this.vahe + this.ruut;
  },
  get baasiees2() {
    return this.baasiees1 - this.vahe - this.ruut;
  },
  get v_baasiees2() {
    return this.v_baasiees1 + this.vahe + this.ruut;
  },
  get teel1() {
    return this.xpool1 - this.vahe - this.ruut;
  },
  get v_teel1() {
    return this.v_xpool1 + this.vahe + this.ruut;
  },
  get teel2() {
    return this.teel1 - this.vahe - this.ruut;
  },
  get v_teel2() {
    return this.teel1 + this.vahe + this.ruut;
  },
  get xpool1() {
    return this.pool - 2 * this.ruut - 2 * this.vahe;
  },
  get v_xpool1() {
    return this.v_xpool2 + this.ruut + this.vahe;
  },
  get xpool2() {
    return this.pool - this.ruut - this.vahe;
  },
  get v_xpool2() {
    return this.pool + this.vahe;
  },
  get y1() {
    return this.vertpool + this.poolvahe;
  },
  get y2() {
    return this.vertpool - this.poolvahe - this.ruut;
  },
  get y3() {
    return this.y1 + this.ruut + this.vahe;
  },
  get y4() {
    return this.y2 - this.vahe - this.ruut;
  },
  get y5() {
    return this.y3 + this.vahe + this.ruut;
  },
  get y6() {
    return this.y4 - this.ruut - this.vahe;
  },
  get lako() {
    return [
      { x: this.xlaev2, y: this.y1 },
      { x: this.xlaev2, y: this.y2 },
      { x: this.xlaev2, y: this.y3 },
      { x: this.xlaev2, y: this.y4 },
      { x: this.xlaev2, y: this.y5 },
      { x: this.xlaev2, y: this.y6 },
      { x: this.xlaev1, y: this.y1 },
      { x: this.xlaev1, y: this.y2 },
      { x: this.xlaev1, y: this.y3 },
      { x: this.xlaev1, y: this.y4 },
      { x: this.xlaev1, y: this.y5 },
      { x: this.xlaev1, y: this.y6 },
    ];
  },
  get kako() {
    return [
      { x: this.xkaitse, y: this.y1 },
      { x: this.xkaitse, y: this.y2 },
      { x: this.xkaitse, y: this.y3 },
      { x: this.xkaitse, y: this.y4 },
    ];
  },
  get keko() {
    return [
      { x: this.xpool2, y: this.y1 },
      { x: this.xpool2, y: this.y2 },
      { x: this.xpool2, y: this.y3 },
      { x: this.xpool2, y: this.y4 },
      { x: this.xpool2, y: this.y5 },
      { x: this.xpool2, y: this.y6 },
      { x: this.xpool1, y: this.y1 },
      { x: this.xpool1, y: this.y2 },
      { x: this.xpool1, y: this.y3 },
      { x: this.xpool1, y: this.y4 },
      { x: this.xpool1, y: this.y5 },
      { x: this.xpool1, y: this.y6 },
    ];
  },
  get v_keko() {
    return [
      { x: this.v_xpool2, y: this.y1 },
      { x: this.v_xpool2, y: this.y2 },
      { x: this.v_xpool2, y: this.y3 },
      { x: this.v_xpool2, y: this.y4 },
      { x: this.v_xpool2, y: this.y5 },
      { x: this.v_xpool2, y: this.y6 },
      { x: this.v_xpool1, y: this.y1 },
      { x: this.v_xpool1, y: this.y2 },
      { x: this.v_xpool1, y: this.y3 },
      { x: this.v_xpool1, y: this.y4 },
      { x: this.v_xpool1, y: this.y5 },
      { x: this.v_xpool1, y: this.y6 },
    ];
  },
  get v_kako() {
    return [
      { x: this.v_xkaitse, y: this.y1 },
      { x: this.v_xkaitse, y: this.y2 },
      { x: this.v_xkaitse, y: this.y3 },
      { x: this.v_xkaitse, y: this.y4 },
    ];
  },
  get v_lako() {
    return [
      { x: this.v_xlaev2, y: this.y1 },
      { x: this.v_xlaev2, y: this.y2 },
      { x: this.v_xlaev2, y: this.y3 },
      { x: this.v_xlaev2, y: this.y4 },
      { x: this.v_xlaev2, y: this.y5 },
      { x: this.v_xlaev2, y: this.y6 },
      { x: this.v_xlaev1, y: this.y1 },
      { x: this.v_xlaev1, y: this.y2 },
      { x: this.v_xlaev1, y: this.y3 },
      { x: this.v_xlaev1, y: this.y4 },
      { x: this.v_xlaev1, y: this.y5 },
      { x: this.v_xlaev1, y: this.y6 },
    ];
  },
  get baasirynne() {
    return [
      { x: this.baasiees1, y: this.y1 },
      { x: this.baasiees1, y: this.y2 },
      { x: this.baasiees1, y: this.y3 },
      { x: this.baasiees1, y: this.y4 },
      { x: this.baasiees1, y: this.y5 },
      { x: this.baasiees1, y: this.y6 },
      { x: this.baasiees2, y: this.y1 },
      { x: this.baasiees2, y: this.y2 },
      { x: this.baasiees2, y: this.y3 },
      { x: this.baasiees2, y: this.y4 },
      { x: this.baasiees2, y: this.y5 },
      { x: this.baasiees2, y: this.y6 },
    ];
  },
  get v_baasirynne() {
    return [
      { x: this.v_baasiees1, y: this.y1 },
      { x: this.v_baasiees1, y: this.y2 },
      { x: this.v_baasiees1, y: this.y3 },
      { x: this.v_baasiees1, y: this.y4 },
      { x: this.v_baasiees1, y: this.y5 },
      { x: this.v_baasiees1, y: this.y6 },
      { x: this.v_baasiees2, y: this.y1 },
      { x: this.v_baasiees2, y: this.y2 },
      { x: this.v_baasiees2, y: this.y3 },
      { x: this.v_baasiees2, y: this.y4 },
      { x: this.v_baasiees2, y: this.y5 },
      { x: this.v_baasiees2, y: this.y6 },
    ];
  },
  get baasiryndevastu() {
    return [
      { x: this.baasiees1, y: this.y1 },
      { x: this.baasiees1, y: this.y2 },
      { x: this.baasiees1, y: this.y3 },
      { x: this.baasiees1, y: this.y4 },
      { x: this.baasiees1, y: this.y5 },
      { x: this.baasiees1, y: this.y6 },
      { x: this.v_xlaev2, y: this.y1 },
      { x: this.v_xlaev2, y: this.y2 },
      { x: this.v_xlaev2, y: this.y3 },
      { x: this.v_xlaev2, y: this.y4 },
      { x: this.v_xlaev2, y: this.y5 },
      { x: this.v_xlaev2, y: this.y6 },
    ];
  },
  get v_baasiryndevastu() {
    return [
      { x: this.v_baasiees1, y: this.y1 },
      { x: this.v_baasiees1, y: this.y2 },
      { x: this.v_baasiees1, y: this.y3 },
      { x: this.v_baasiees1, y: this.y4 },
      { x: this.v_baasiees1, y: this.y5 },
      { x: this.v_baasiees1, y: this.y6 },
      { x: this.xlaev2, y: this.y1 },
      { x: this.xlaev2, y: this.y2 },
      { x: this.xlaev2, y: this.y3 },
      { x: this.xlaev2, y: this.y4 },
      { x: this.xlaev2, y: this.y5 },
      { x: this.xlaev2, y: this.y6 },
    ];
  },
  get teelkohtumine() {
    return [
      { x: this.teel1, y: this.y1 },
      { x: this.teel1, y: this.y2 },
      { x: this.teel1, y: this.y3 },
      { x: this.teel1, y: this.y4 },
      { x: this.teel1, y: this.y5 },
      { x: this.teel1, y: this.y6 },
      { x: this.teel2, y: this.y1 },
      { x: this.teel2, y: this.y2 },
      { x: this.teel2, y: this.y3 },
      { x: this.teel2, y: this.y4 },
      { x: this.teel2, y: this.y5 },
      { x: this.teel2, y: this.y6 },
    ];
  },
  get v_teelkohtumine() {
    return [
      { x: this.v_teel1, y: this.y1 },
      { x: this.v_teel1, y: this.y2 },
      { x: this.v_teel1, y: this.y3 },
      { x: this.v_teel1, y: this.y4 },
      { x: this.v_teel1, y: this.y5 },
      { x: this.v_teel1, y: this.y6 },
      { x: this.v_teel2, y: this.y1 },
      { x: this.v_teel2, y: this.y2 },
      { x: this.v_teel2, y: this.y3 },
      { x: this.v_teel2, y: this.y4 },
      { x: this.v_teel2, y: this.y5 },
      { x: this.v_teel2, y: this.y6 },
    ];
  },
};
const algpakk = [
  1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 4, 4, 5, 5, 5, 7, 7, 8, 8, 9, 10,
  11, 12, 13, 14, 14, 15, 15,
];
function suva(pikkus) {
  return Math.floor(Math.random() * pikkus);
}
function segamine(arr) {
  let segaarr = [...arr];
  let j = 0;
  for (let i = segaarr.length - 1; i > 0; i--) {
    j = suva(i + 1);
    [segaarr[i], segaarr[j]] = [segaarr[j], segaarr[i]];
  }
  return segaarr;
}
function Mang({ keel, mangustate, setMangustate }) {
  const canvasRef = useRef(null);
  const emalaevRef = useRef(null);
  const spriteRef = useRef(null);
  const kosmosRef = useRef(null);
  const intervalRef = useRef(null);
  const lahingRef = useRef(null);
  const minukaik = useRef(true);
  const vastasekaardikaimine = useRef(false);
  const rakettlendab = useRef(null);
  const intervall = 50;
  const anima_kestus = 1000;
  const puru_kestus = 500;
  const pausi_kestus = 500;
  const nodraw = useRef(null);
  const manglabi = useRef(false);

  const kaardid = {
    emalaevastatid: {
      tyyp: 0,
      nimi: keel === "est" ? "Emalaev" : "Mothership",
      rynnak: 1,
      kaitse: 10,
      klass: 0,
    },
    havitaja: {
      tyyp: 1,
      nimi: keel === "est" ? "Hävitaja" : "Fighter",
      rynnak: 1,
      kaitse: 1,
      klass: 2,
      pilt: 0,
      maks: 1,
    },
    pommitaja: {
      tyyp: 2,
      nimi: keel === "est" ? "Pommitaja" : "Bomber",
      rynnak: 2,
      kaitse: 1,
      klass: 3,
      pilt: 18,
      maks: 1,
    },
    rakett: {
      tyyp: 3,
      nimi: keel === "est" ? "Tuumarakett" : "Nuclear Missile",
      rynnak: 3,
      klass: 4,
      pilt: 14,
      maks: 3,
    },
    laser: {
      tyyp: 4,
      nimi: keel === "est" ? "Laserkahur" : "Laser turret",
      rynnak: 1,
      kaitse: 2,
      klass: 1,
      pilt: 8,
      maks: 2,
    },
    varghavitaja: {
      tyyp: 5,
      nimi: keel === "est" ? "Varghävitaja" : "Cloaked fighter",
      rynnak: 1,
      kaitse: 1,
      klass: 2,
      pilt: 26,
      maks: 2,
    },
    relsiheitja: {
      tyyp: 6,
      nimi: keel === "est" ? "Relsiheitja" : "Rail launcher",
      rynnak: 1,
      kaitse: 1,
      klass: 3,
      pilt: 2,
      maks: 2,
    },
    vargpommitaja: {
      tyyp: 7,
      nimi: keel === "est" ? "Vargpommitaja" : "Cloaked bomber",
      rynnak: 2,
      kaitse: 1,
      klass: 3,
      pilt: 28,
      maks: 2,
    },
    raskepommitaja: {
      tyyp: 8,
      nimi: keel === "est" ? "Raskepommitaja" : "Heavy bomber",
      rynnak: 3,
      kaitse: 2,
      klass: 3,
      pilt: 12,
      maks: 2,
    },
    kilbikandja: {
      tyyp: 9,
      nimi: keel === "est" ? "Kilbikandja" : "Shieldbearer",
      rynnak: 0,
      kaitse: 1,
      klass: 5,
      pilt: 6,
      maks: 3,
    },
    utiliseerija: {
      tyyp: 10,
      nimi: keel === "est" ? "Utiliseerija" : "Salvager",
      rynnak: 0,
      kaitse: 2,
      klass: 5,
      pilt: 24,
      maks: 2,
    },
    remondilaev: {
      tyyp: 11,
      nimi: keel === "est" ? "Remondilaev" : "Repair vessel",
      rynnak: 0,
      kaitse: 2,
      klass: 5,
      pilt: 22,
      maks: 2,
    },
    relsskahur: {
      tyyp: 12,
      nimi: keel === "est" ? "Relsskahur" : "Railgun turret",
      rynnak: 1,
      kaitse: 2,
      klass: 1,
      pilt: 20,
      maks: 3,
    },
    kilbigeneraator: {
      tyyp: 13,
      nimi: keel === "est" ? "Kilbigeneraator" : "Shield generator",
      rynnak: 0,
      kaitse: 1,
      klass: 1,
      pilt: 4,
      maks: 3,
    },
    mitmikrakett: {
      tyyp: 14,
      nimi: keel === "est" ? "Mitmikrakett" : "Multi-warhead missile",
      rynnak: 1,
      klass: 4,
      pilt: 10,
      maks: 3,
    },
    raskehavitaja: {
      tyyp: 15,
      nimi: keel === "est" ? "Raskehävitaja" : "Heavy Fighter",
      rynnak: 2,
      kaitse: 2,
      klass: 2,
      pilt: 16,
      maks: 2,
    },
  };
  function j6ud() {
    return {
      v_kokku:
        vastaselaevadbaasis.length +
        vastaselaevadkeskel.length +
        vastasekaitse.length,
      m_kokku:
        minulaevadbaasis.length + minulaevadkeskel.length + minukaitse.length,
      v_laevad_kokku: vastaselaevadbaasis.length + vastaselaevadkeskel.length,
      m_laevad_kokku: minulaevadbaasis.length + minulaevadkeskel.length,
      v_kaitse: vastasekaitse.length,
      m_kaitse: minukaitse.length,
      v_keskel: vastaselaevadkeskel.length,
      m_keskel: minulaevadkeskel.length,
      v_baasis: vastaselaevadbaasis.length,
      m_baasis: minulaevadbaasis.length,
      get v_havitajaidkeskel() {
        if (this.v_keskel > 0) {
          return vastaselaevadkeskel.filter((a) => lookup(a.tyyp).klass === 2)
            .length;
        } else return 0;
      },
      get m_havitajaidkeskel() {
        return minulaevadkeskel.filter((a) => lookup(a.tyyp).klass === 2)
          .length;
      },
      get v_pommitajaidkeskel() {
        return vastaselaevadkeskel.filter((a) => lookup(a.tyyp).klass === 3)
          .length;
      },
      get m_pommitajaidkeskel() {
        return minulaevadkeskel.filter((a) => lookup(a.tyyp).klass === 3)
          .length;
      },
      get v_havitajaidbaasis() {
        return vastaselaevadbaasis.filter((a) => lookup(a.tyyp).klass === 2)
          .length;
      },
      get m_havitajaidbaasis() {
        return minulaevadbaasis.filter((a) => lookup(a.tyyp).klass === 2)
          .length;
      },
      get v_pommitajaidbaasis() {
        return vastaselaevadbaasis.filter((a) => lookup(a.tyyp).klass === 3)
          .length;
      },
      get m_pommitajaidbaasis() {
        return minulaevadbaasis.filter((a) => lookup(a.tyyp).klass === 3)
          .length;
      },
      get v_havitajaid() {
        return this.v_havitajaidbaasis + this.v_havitajaidkeskel;
      },
      get m_havitajaid() {
        return this.m_havitajaidbaasis + this.m_havitajaidkeskel;
      },
      get v_pommitajaid() {
        return this.v_pommitajaidbaasis + this.v_pommitajaidkeskel;
      },
      get m_pommitajaid() {
        return this.m_pommitajaidbaasis + this.m_pommitajaidkeskel;
      },
    };
  }
  function lookup(kaardi_tyyp) {
    return Object.values(kaardid).find((value) => value.tyyp === kaardi_tyyp);
  }
  const [minupakk, setMinupakk] = useState(algpakk);
  const [vastasepakk, setVastasepakk] = useState(algpakk);
  const [minukasi, setMinukasi] = useState([]); //    { tyyp: 14, id: 300 },    { tyyp: 3, id: 301 },
  const [vastasekasi, setVastasekasi] = useState([]); //{ tyyp: 14, id: 800 },{ tyyp: 3, id: 801 }
  const [minuhp, setMinuhp] = useState(kaardid.emalaevastatid.kaitse);
  const [vastasehp, setVastasehp] = useState(10);
  const [minumana, setMinumana] = useState(1);
  const [vastasemana, setVastasemana] = useState(1);
  const [minuprygi, setMinuprygi] = useState([]);
  const [vastaseprygi, setVastaseprygi] = useState([]);
  const [minukaitse, setMinukaitse] = useState([]); //{tyyp: 4,id: 105,hp: 1,vastane: false,},
  const [vastasekaitse, setVastasekaitse] = useState([]); //{tyyp:4,id:600,hp:2,vastane:true}{tyyp:13,id:600,hp:2,vastane:true}
  const [minulaevadbaasis, setMinulaevadbaasis] = useState([]);
  const [vastaselaevadbaasis, setVastaselaevadbaasis] = useState([]); //{tyyp:1,id:700,hp:1,vastane:true}    { tyyp: 1, id: 701, hp: 1, vastane: true },  { tyyp: 9, id: 702, hp: 1, vastane: true },
  const [minulaevadkeskel, setMinulaevadkeskel] = useState([]);
  const [vastaselaevadkeskel, setVastaselaevadkeskel] = useState([]); //
  const [minuyksuseloendur, setMinuyksuseloendur] = useState(0);
  const [vastaseyksuseloendur, setVastaseyksuseloendur] = useState(500);
  const [minukaardiloendur, setMinukaardiloendur] = useState(0);
  const [vastasekaardiloendur, setVastasekaardiloendur] = useState(0);
  const [kaik, setKaik] = useState(1);
  const [vastasekaiklabi, setVastasekaiklabi] = useState(0);
  const [vastasekaardidkaidud, setVastasekaardidkaidud] = useState(0);
  const [minukaiklabi, setMinukaiklabi] = useState(0);
  const [minukaikjalle, setMinukaikjalle] = useState(0);
  const [lahingujargne, setLahingujargne] = useState(0);
  const [relsidkainud, setRelsidkainud] = useState(0);
  const [strateegia, setStrateegia] = useState("kilp");
  const [pildidlaetud, setPildidlaetud] = useState(false);
  const [animakaib, setAnimakaib] = useState(0);
  const [lahinganima, setLahinganima] = useState(0);

  function mangulopp() {
    vaba = false;
    manglabi.current = true;
    console.log("MÄNG ON LÄBI");
  }

  function ai(taktikavalik = false) {
    let hoiamana = false;
    let valik = 0;
    let valitudkaart = {};
    let joud = j6ud();

    function kuivastanekeskel(taiendus = []) {
      let vahekord = 0;
      let motivatsioon = 0;
      let lisamotiivid = 0;
      let m_havidamm = 0;
      let v_havidamm = 0;
      let m_kaitse = 0;
      let v_pommitajad = 0;
      let v_laevastik = [...vastaselaevadkeskel, ...taiendus];
      minulaevadbaasis
        .filter((a) => lookup(a.tyyp).klass === 2)
        .forEach((b) => (m_havidamm += b.tyyp === 1 ? 1 : 1.5));
      minulaevadbaasis.some((a) => (a.tyyp === 9 ? (m_havidamm += 2) : ""));
      vastaselaevadkeskel
        .filter((a) => lookup(a.tyyp).klass === 2)
        .forEach((b) => (v_havidamm += b.tyyp === 1 ? 1 : 1.5));
      vastaselaevadkeskel.some((a) => (a.tyyp === 9 ? (v_havidamm += 2) : ""));
      minukaitse.forEach((a) => (m_kaitse += a.tyyp === 13 ? 3 : 2));
      vastaselaevadkeskel
        .filter((a) => lookup(a.tyyp).klass === 3)
        .forEach((b) => (v_pommitajad += b.tyyp === 2 ? 2 : 2.5));
      vahekord =
        v_havidamm -
        m_havidamm +
        (v_pommitajad - m_kaitse > 0
          ? (v_pommitajad - m_kaitse) * 2
          : v_pommitajad - m_kaitse);

      //lisatingimused rünnaku kasuks
      if (
        minukaitse.some((a) => a.tyyp === 4) &&
        vastasekasi.some((a) => lookup(a.tyyp).klass === 4) &&
        vastasemana >= 2
      ) {
        lisamotiivid += 1;
      }
      if (minukaitse.some((a) => a.tyyp === 12)) {
        lisamotiivid += 1;
      }
      if (
        vastaselaevadkeskel.some((a) => a.tyyp === 11) &&
        vastaselaevadkeskel.filter((a) => lookup(a.tyyp).kaitse > 1).length > 2
      ) {
        lisamotiivid += 1;
      }

      if (minuhp - vahekord >= 7) {
        motivatsioon = 0 + lisamotiivid;
      } else if (minuhp - vahekord >= 4) {
        motivatsioon = 1 + lisamotiivid;
      } else {
        motivatsioon = 2 + lisamotiivid;
      }

      return motivatsioon;
    }
    function ohuanalyys(test = []) {
      //mitu pommitajat, mitu hävitajat, kas kilbik, kas relss

      //eeldusel, et ma ründan täna, on mul homme vähem laevu (50%) vastase kaitseehitiste arvust ja kaitseehitisi/baasilaevu samavõrra 50% minu hävitajate arvust
      //kas ai peaks eeldama, et ma ei ründa täna, kui see on ebamõistlik (kuidas hinnata - kas kaotatud laevade arv vs võimalik dämm emalaevale)
      //ai peaks eeldama, et ma ründan homme, kui võimalik dämm emalaevale on oluliselt suurem
      //testima nii tänast rünnakut, homset kui tänast+homset
      let arr_kaitse = [...vastasekaitse];
      let arr_baasilaevad = [...vastaselaevadbaasis];
      for (let i = 0; i < test.length; i++) {
        if (
          lookup(test[i].tyyp).klass === 2 ||
          lookup(test[i].tyyp).klass === 3
        ) {
          arr_baasilaevad.push(test[i]);
        } else if (lookup(test[i].tyyp).klass === 1) {
          arr_kaitse.push(test[i]);
        }
      }
      let baasikilp = arr_kaitse.some((a) => a.tyyp === 13) ? 3 : 0;
      let laevakilp = arr_baasilaevad.some((a) => a.tyyp === 9) ? 2 : 0;
      function oht(homme = false) {
        let pommidamm = 0;
        let havidamm =
          joud.m_havitajaidkeskel +
            (homme ? joud.m_havitajaidbaasis : 0) -
            arr_kaitse.length -
            arr_baasilaevad.length >
          0
            ? joud.m_havitajaidkeskel +
              (homme ? joud.m_havitajaidbaasis : 0) -
              arr_kaitse.length -
              arr_baasilaevad.length
            : 0; //hävitajate arv miinus kaitsjate arv
        minulaevadkeskel.forEach((a) =>
          lookup(a.tyyp).klass === 2 ? (pommidamm += lookup(a.tyyp).rynnak) : ""
        );
        if (homme) {
          minulaevadbaasis.forEach((a) =>
            lookup(a.tyyp).klass === 2
              ? (pommidamm += lookup(a.tyyp).rynnak)
              : ""
          );
        }
        pommidamm /= joud.v_kaitse + 1; //pommitajate tehtav dämm jagatud kaitseehitiste + emalaev arvuga
        return vastasehp - havidamm - pommidamm >= 7
          ? 1
          : vastasehp - havidamm - pommidamm >= 4
          ? 2
          : 3;
      }
      const ohttana = oht();
      const ohthomme = oht(true);

      return [ohttana, ohthomme];
    }
    function kasminulaual(tyyp) {
      const otsitav = (a) => a.tyyp === tyyp;
      return minukaitse.some(otsitav) || minulaevadbaasis.some(otsitav);
    }
    function kasvastaselaual(tyyp) {
      const otsitav = (a) => a.tyyp === tyyp;
      return vastasekaitse.some(otsitav) || vastaselaevadbaasis.some(otsitav);
    }
    function kasmana(tyyp) {
      return vastasemana >= lookup(tyyp).maks;
    }
    function kaskaes(tyyp) {
      const otsitav = (a) => a.tyyp === tyyp;
      return vastasekasi.some(otsitav);
    }
    function kastasub(tyyp) {
      //tuumarakett - kui minul laserkahur, ei tasu
      if (tyyp === 3 && kasminulaual(4)) {
        return false;
      }
      //mitmikrakett - kui minul laserkahur ja minul keskel alla 3 laeva, ei tasu
      if (tyyp === 14) {
        if (
          (kasminulaual(4) && joud.m_keskel < 3) ||
          (joud.m_keskel < 1 && joud.m_baasis < 3)
        ) {
          return false;
        }
      }
      return true;
    }
    function kasmahub(tyyp) {
      if (
        lookup(tyyp).klass === 2 ||
        lookup(tyyp).klass === 3 ||
        lookup(tyyp).klass === 5
      ) {
        if (joud.v_laevad_kokku < 12) {
          return true;
        } else return false;
      }
      if (lookup(tyyp).klass === 1) {
        if (joud.v_kaitse < 4) {
          return true;
        } else return false;
      }
      if (lookup(tyyp).klass === 4) return true;
    }
    function kaardi_otsustus(valikud) {
      //massiivi ylesehitus: tyyp, kas hoida raha, tyyp, kas hoida raha jne.
      //kontrollime kas on massiiv
      if (Array.isArray(valikud) === false) {
        console.log("valikute massiiv ei ole massiiv");
        return;
      }
      //kontrollime kas massiiv vastab nõuetele
      for (let i = 0; i < valikud.length; i++) {
        if (i === 0 && typeof valikud[0] !== "number") {
          console.log("valikute massiiv on vigane, esimene pole number");
          return;
        }
        if (i > 0 && i % 2 === 0 && typeof valikud[i] !== "number") {
          console.log("valikute massiiv on vigane, paaris pole number");
          return;
        }
        if (i > 0 && i % 2 !== 0 && typeof valikud[i] !== "boolean") {
          console.log("valikute massiiv on vigane, paaritu pole boolean");
          return;
        }
      }
      //hakkame massiivi üle ühe elemendi läbi töötama
      if (hoiamana === false && valik < 1) {
        for (let i = 0; i < valikud.length; i += 2) {
          if (kaskaes(valikud[i])) {
            if (kasmahub(valikud[i])) {
              if (kasmana(valikud[i])) {
                if (kastasub(valikud[i])) {
                  valik = [valikud[i]][0];
                  break;
                }
              } else {
                if (valikud[i + 1]) {
                  if (kastasub(valikud[i])) {
                    hoiamana = true;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    function koosta_kaardivalik() {
      let vastaselaevad = [...vastaselaevadbaasis, ...vastaselaevadkeskel];
      let minulaevad = [...minulaevadbaasis, ...minulaevadkeskel];
      let polelaserit = minukaitse.some((a) => a.tyyp === 4) === false;
      let polebaasikilpi = minukaitse.some((a) => a.tyyp === 13) === false;
      let polekeskellaevakilpi =
        minulaevadkeskel.some((a) => a.tyyp === 9) === false;
      let polebaasislaevakilpi =
        minulaevadbaasis.some((a) => a.tyyp === 9) === false;
      let kaardivalikuarr = [];

      if (strateegia === "remont") {
        if (polebaasikilpi === false) {
          kaardivalikuarr.push(3, true);
        }
        if (minuhp < 4 && polelaserit && polebaasikilpi) {
          kaardivalikuarr.push(3, true);
        }
        if (joud.m_keskel > 2 && polekeskellaevakilpi && vastasemana > 2) {
          kaardivalikuarr.push(14, true);
        }
        if (
          joud.m_keskel === 0 &&
          joud.m_baasis > 2 &&
          polebaasislaevakilpi &&
          polelaserit &&
          vastasemana > 2
        ) {
          kaardivalikuarr.push(14, true);
        }
        if (vastaselaevad.some((a) => a.type === 11) === false) {
          kaardivalikuarr.push(11, true);
        }
        if (
          vastaselaevad.filter((a) => lookup(a.tyyp).kaitse > 1).length > 3 &&
          vastaselaevad.some((a) => a.type === 9) === false
        ) {
          kaardivalikuarr.push(9, true);
        }
        if (
          vastaselaevad.filter((a) => lookup(a.tyyp).kaitse === 1).length > 3 &&
          vastaselaevad.some((a) => a.type === 10) === false
        ) {
          kaardivalikuarr.push(10, true);
        }
        if (joud.v_havitajaid < 3) {
          kaardivalikuarr.push(
            15,
            true,
            5,
            false,
            1,
            false,
            12,
            false,
            4,
            true
          );
        } else if (joud.v_havitajaid / joud.v_pommitajaid > 3) {
          kaardivalikuarr.push(
            8,
            true,
            7,
            false,
            1,
            false,
            15,
            true,
            5,
            false,
            1,
            false
          );
        } else {
          kaardivalikuarr.push(
            11,
            true,
            15,
            true,
            8,
            true,
            9,
            true,
            13,
            true,
            10,
            true,
            12,
            true,
            4,
            true,
            5,
            false,
            7,
            false,
            1,
            false,
            2,
            false
          );
        }
        if (polelaserit && polebaasikilpi) {
          kaardivalikuarr.push(3, true);
        }
        if (joud.m_keskel > 2 && polekeskellaevakilpi) {
          kaardivalikuarr.push(14, true);
        }
        if (
          joud.m_keskel === 0 &&
          joud.m_baasis > 2 &&
          polebaasislaevakilpi &&
          polelaserit
        ) {
          kaardivalikuarr.push(14, true);
        }
        kaardi_otsustus(kaardivalikuarr);
      }
      if (strateegia === "kilp") {
        if (polebaasikilpi === false) {
          kaardivalikuarr.push(3, true);
        }
        if (minuhp < 4 && polelaserit && polebaasikilpi) {
          kaardivalikuarr.push(3, true);
        }
        if (joud.m_keskel > 2 && polekeskellaevakilpi && vastasemana > 2) {
          kaardivalikuarr.push(14, true);
        }
        if (
          joud.m_keskel === 0 &&
          joud.m_baasis > 2 &&
          polebaasislaevakilpi &&
          polelaserit &&
          vastasemana > 2
        ) {
          kaardivalikuarr.push(14, true);
        }
        if (vastaselaevad.some((a) => a.type === 9) === false) {
          kaardivalikuarr.push(9, true);
        }
        if (
          vastaselaevad.filter((a) => lookup(a.tyyp).kaitse === 1).length > 3 &&
          vastaselaevad.some((a) => a.type === 10) === false
        ) {
          kaardivalikuarr.push(10, true);
        }
        if (
          vastaselaevad.some((a) => a.type === 11) === false &&
          vastaselaevad.filter((a) => lookup(a.tyyp).kaitse > 1).length > 1
        ) {
          kaardivalikuarr.push(11, true);
        }
        if (joud.v_havitajaid < 6) {
          kaardivalikuarr.push(
            5,
            false,
            1,
            false,
            12,
            false,
            4,
            true,
            15,
            false
          );
        } else if (joud.v_havitajaid / joud.v_pommitajaid > 3) {
          kaardivalikuarr.push(
            7,
            false,
            1,
            false,
            5,
            false,
            1,
            false,
            8,
            true,
            15,
            true
          );
        } else {
          kaardivalikuarr.push(
            9,
            true,
            10,
            true,
            13,
            true,
            5,
            true,
            7,
            true,
            1,
            false,
            2,
            false,
            15,
            true,
            8,
            true,
            12,
            true,
            4,
            true,
            11,
            true
          );
        }
        if (polelaserit && polebaasikilpi) {
          kaardivalikuarr.push(3, true);
        }
        if (joud.m_keskel > 2 && polekeskellaevakilpi) {
          kaardivalikuarr.push(14, true);
        }
        if (
          joud.m_keskel === 0 &&
          joud.m_baasis > 2 &&
          polebaasislaevakilpi &&
          polelaserit
        ) {
          kaardivalikuarr.push(14, true);
        }
        kaardi_otsustus(kaardivalikuarr);
      }
      if (strateegia !== "remont") {
        if (
          minuhp <= 3 &&
          minukaitse.some((a) => a.tyyp === 4) === false &&
          vastasemana >= 2
        ) {
          kaardi_otsustus([3, true]);
        }
        if (vastasemana >= 3) {
          kaardi_otsustus([13, false, 9, false]);
        }
        if (
          [...vastaselaevadbaasis, ...vastaselaevadkeskel].filter(
            (a) => lookup(a.tyyp).kaitse
          ).length > 1 &&
          vastasemana >= 2
        ) {
          kaardi_otsustus([11, true]);
        }
        if (
          joud.m_keskel > 2 &&
          minulaevadkeskel.some((a) => a.tyyp === 9) === false &&
          vastasemana >= 3
        ) {
          kaardi_otsustus([14, true]);
        }
        if (
          joud.m_pommitajaid > joud.v_kaitse &&
          joud.m_havitajaid <= joud.v_havitajaid
        ) {
          kaardi_otsustus([13, false, 12, false, 4, false, 1, false]);
        }
        if (
          joud.m_havitajaid > joud.v_havitajaid &&
          joud.m_pommitajaid <= joud.m_kaitse
        ) {
          kaardi_otsustus([15, false, 5, false, 1, false, 12, false, 4, false]);
        }
        if (
          joud.m_havitajaid > joud.v_havitajaid + 1 ||
          joud.v_havitajaid < 3
        ) {
          if (joud.m_keskel <= joud.v_havitajaid + joud.v_kaitse) {
            kaardi_otsustus([12, false, 4, true, 5, false, 1, true]);
          }
          if (joud.m_keskel > joud.v_havitajaid + joud.v_kaitse) {
            kaardi_otsustus([12, false, 4, false, 5, false, 1, true]);
          }
        }
        if (joud.m_havitajaid <= joud.v_havitajaid && joud.v_havitajaid >= 3) {
          if (joud.m_havitajaid + 1 < joud.v_havitajaid) {
            kaardi_otsustus([3, false, 8, false, 7, false, 2, true]);
          } else
            kaardi_otsustus([
              3,
              false,
              8,
              false,
              7,
              false,
              2,
              false,
              12,
              false,
              4,
              false,
              5,
              false,
              1,
              true,
            ]);
        }
      }
    }
    function vali_taktika() {
      if (joud.v_keskel > 0) {
        if (kuivastanekeskel() >= 2) {
          v_rynnata = true;
          console.log("vastane otsustas sinu emalaeva rünnata");
          return;
        } else if (joud.m_havitajaid > joud.v_havitajaidkeskel + 1) {
          console.log("vastane otsustas keskelt taanduda");
          v_taanduda = true;
          return;
        } else if (joud.v_baasis > 0) {
          console.log("vastane otsustas laevastikku täiendada");
          v_edasiliikuda = true;
          return;
        }
      }
      if (joud.m_keskel > 0) {
        if (
          joud.m_havitajaidkeskel <= joud.v_havitajaid &&
          joud.v_havitajaid > 0 &&
          minulaevadkeskel.some((a) => a.tyyp === 9) === false
        ) {
          v_edasiliikuda = true;
          console.log("vastane otsustas keskpaika tungida");
          return;
        }
      }
      if (joud.m_keskel === 0 && joud.v_keskel === 0) {
        if (joud.m_havitajaid <= joud.v_havitajaid && joud.v_havitajaid > 0) {
          v_edasiliikuda = true;
          console.log("vastane otsustas keskpaiga üle võtta");
          return;
        }
      }
      console.log("vastane otsustas mitte midagi teha");
      return;
    }
    if (taktikavalik === false) {
      vastasekaardikaimine.current = true;
      koosta_kaardivalik();
      if (valik > 0) {
        console.log("vastane otsustas ehitada " + lookup(valik).nimi);
        valitudkaart = vastasekasi.find((a) => a.tyyp === valik);
        kaartlauda(valitudkaart, true);
      } else {
        console.log("vastane ei ehitanud midagi");
        vastasekaardikaimine.current = false;
        setVastasekaardidkaidud(vastasekaardidkaidud + 1);
      }
    }
    if (taktikavalik) {
      vali_taktika();
      setVastasekaiklabi(vastasekaiklabi + 1);
    }
  }

  function raketilask(tyyp, vastane = false) {
    let joud = j6ud();
    let emalaevahp = vastane ? minuhp : vastasehp;
    let laevastik;
    let mislaevastik;
    let sihtmark;
    let sihtmargid = [];

    function tuumakas() {
      if (vastane === true) {
        if (minukaitse.some((a) => a.tyyp === 13)) {
          rakettlendab.current = {
            vastane: vastane,
            tyyp: tyyp,
            laser: false,
            kilp: true,
          };
          anima[0] = anima_kestus * 2.5;
          anima[1] = anima[0];
          alustaIntervall(rakettIntervall);
          setTimeout(() => {
            console.log("vastane hävitas tuumaraketiga sinu kilbigeneraatori!");
            let uusarr = minukaitse.filter((a) => a.tyyp !== 13);
            rakettlendab.current = null;
            setMinukaitse(uusarr);
            setVastasekaardidkaidud(vastasekaardidkaidud + 1);
          }, anima[0]);
        } else {
          rakettlendab.current = {
            vastane: vastane,
            tyyp: tyyp,
            laser: false,
            kilp: false,
            puru: emalaevahp < 4 ? true : false,
          };
          anima[0] = rakettlendab.current.puru
            ? anima_kestus * 4.5
            : anima_kestus * 2.5;
          anima[1] = anima[0];
          alustaIntervall(rakettIntervall);
          setTimeout(() => {
            rakettlendab.current.puru
              ? console.log("vastane hävitas sinu emalaeva!")
              : console.log("vastane lasi sind tuumaraketiga!");
            rakettlendab.current = null;
            emalaevahp -= 3;
            setMinuhp(emalaevahp);
            setVastasekaardidkaidud(vastasekaardidkaidud + 1);
          }, anima[0]);
        }
      } else {
        vaba = false;
        if (vastasekaitse.some((a) => a.tyyp === 4) === false) {
          if (vastasekaitse.some((a) => a.tyyp === 13)) {
            rakettlendab.current = {
              vastane: vastane,
              tyyp: tyyp,
              laser: false,
              kilp: true,
            };
            anima[0] = anima_kestus * 2.5;
            anima[1] = anima[0];
            alustaIntervall(rakettIntervall);
            setTimeout(() => {
              console.log(
                "sa hävitasid tuumaraketiga vastase kilbigeneraatori!"
              );
              let uusarr = vastasekaitse.filter((a) => a.tyyp !== 13);
              rakettlendab.current = null;
              setVastasekaitse(uusarr);
            }, anima[0]);
          } else {
            rakettlendab.current = {
              vastane: vastane,
              tyyp: tyyp,
              laser: false,
              kilp: false,
              puru: emalaevahp < 4 ? true : false,
            };
            anima[0] = rakettlendab.current.puru
              ? anima_kestus * 4.5
              : anima_kestus * 2.5;
            anima[1] = anima[0];
            alustaIntervall(rakettIntervall);
            setTimeout(() => {
              rakettlendab.current.puru
                ? console.log("hävitasid vastase emalaeva!")
                : console.log("lasid vastast tuumaraketiga!");
              rakettlendab.current = null;
              emalaevahp -= 3;
              vaba = true;
              setVastasehp(emalaevahp);
            }, anima[0]);
          }
        } else {
          rakettlendab.current = {
            vastane: vastane,
            tyyp: tyyp,
            laser: true,
            kilp: false,
            puru: false,
          };
          anima[0] = anima_kestus * 2;
          anima[1] = anima[0];
          alustaIntervall(rakettIntervall);
          setTimeout(() => {
            console.log(
              "lasid vastast tuumaraketiga, aga tema laser lasi selle alla"
            );
            rakettlendab.current = null;
            vaba = true;
            joonistamine();
          }, anima[0]);
        }
      }
    }
    if (tyyp === 3) {
      tuumakas();
    }

    function mitmik() {
      if (vastane) {
        laevastik =
          joud.m_keskel > 0
            ? [...minulaevadkeskel]
            : joud.m_baasis > 0
            ? [...minulaevadbaasis]
            : "";
        mislaevastik =
          joud.m_keskel > 0 ? "mlk" : joud.m_baasis > 0 ? "mlb " : "";
      } else {
        vaba = false;
        laevastik =
          joud.v_keskel > 0
            ? [...vastaselaevadkeskel]
            : joud.v_baasis > 0
            ? [...vastaselaevadbaasis]
            : "";
        mislaevastik =
          joud.v_keskel > 0 ? "vlk" : joud.v_baasis > 0 ? "vlb" : "";
      }
      if (laevastik.length === 0) {
        rakettlendab.current = {
          vastane: vastane,
          tyyp: tyyp,
          tyhja: true,
        };
        anima[0] = anima_kestus * 2;
        anima[1] = anima[0];
        alustaIntervall(rakettIntervall);
        setTimeout(() => {
          console.log(
            "Rakett ei leidnud vastase laevu ja lendas kahjutult avakosmosesse."
          );
          rakettlendab.current = null;
          if (vastane) {
            setVastasekaardidkaidud(vastasekaardidkaidud + 1);
          } else vaba = true;
          joonistamine();
        }, anima[0]);
      } else {
        if (
          (vastane === false &&
            (joud.v_keskel > 0 ||
              (joud.v_keskel === 0 &&
                vastasekaitse.some((a) => a.tyyp === 4) === false))) ||
          (vastane &&
            (joud.m_keskel > 0 ||
              (joud.m_keskel === 0 &&
                minukaitse.some((a) => a.tyyp === 4) === false)))
        ) {
          for (let i = 0; i < 3; i++) {
            const jrk = suva(laevastik.length);
            sihtmark = laevastik[jrk];
            if (laevastik.some((a) => a.tyyp === 9) && i < 2) {
              sihtmark.kilp = true;
            }
            if (laevastik.some((a) => a.tyyp === 9) === false || i === 2) {
              laevastik[jrk].hp -= 1;
            }
            if (laevastik[jrk].hp < 1) {
              sihtmark.puru = true;
            }
            sihtmargid.push(sihtmark);
          }
          rakettlendab.current = {
            vastane: vastane,
            tyyp: tyyp,
            laser: false,
            sihtmargid: sihtmargid,
          };
          console.log(rakettlendab.current.sihtmargid);
          anima[0] = anima_kestus * 2.7;
          anima[1] = anima[0];
          alustaIntervall(rakettIntervall);
          setTimeout(() => {
            console.log(
              sihtmargid[0].kilp
                ? "Lõhkepea tabas kilpi."
                : "Lõhkepea tabas " +
                    lookup(sihtmargid[0].tyyp).nimi +
                    (sihtmargid[0].puru ? "t ja hävitas selle." : "t.")
            );
            console.log(
              sihtmargid[1].kilp
                ? "Lõhkepea tabas kilpi ja hävitas selle."
                : "Lõhkepea tabas " +
                    lookup(sihtmargid[1].tyyp).nimi +
                    (sihtmargid[1].puru ? "t ja hävitas selle." : "t.")
            );
            console.log(
              "Lõhkepea tabas " +
                lookup(sihtmargid[2].tyyp).nimi +
                (sihtmargid[2].puru ? "t ja hävitas selle." : "t.")
            );
            if (mislaevastik === "mlk") {
              setMinulaevadkeskel(laevastik.filter((a) => a.hp > 0));
            }
            if (mislaevastik === "mlb") {
              setMinulaevadbaasis(laevastik.filter((a) => a.hp > 0));
            }
            if (mislaevastik === "vlk") {
              setVastaselaevadkeskel(laevastik.filter((a) => a.hp > 0));
            }
            if (mislaevastik === "vlb") {
              setVastaselaevadbaasis(laevastik.filter((a) => a.hp > 0));
            }
            rakettlendab.current = null;
            if (vastane) {
              setVastasekaardidkaidud(vastasekaardidkaidud + 1);
            } else vaba = true;
            joonistamine();
          }, anima[0]);
        } else {
          rakettlendab.current = {
            vastane: vastane,
            tyyp: tyyp,
            laser: true,
          };
          anima[0] = anima_kestus * 2;
          anima[1] = anima[0];
          alustaIntervall(rakettIntervall);
          setTimeout(() => {
            console.log("Laserkahur lasi raketi kahjutult alla.");
            rakettlendab.current = null;
            vaba = true;
            joonistamine();
          }, anima[0]);
        }
      }
    }

    if (tyyp === 14) {
      mitmik();
    }
  }

  function kaigulopp2() {
    taanduda = false;
    edasiliikuda = false;
    rynnata = false;
    v_edasiliikuda = false;
    v_rynnata = false;
    v_taanduda = false;
    let praegunekaik = kaik;
    praegunekaik++;
    setKaik(praegunekaik);
    jagakaarte(1, 1);
    muudamana(1);
    muudamana(1, true);
    setMinukaikjalle(kaik);
  }

  function kaigulopp() {
    const joud = j6ud();
    function liigu(mina = "", vastane = "") {
      let minualguspunkt;
      let vastasealguspunkt;
      let minulopppunkt;
      let vastaselopppunkt;
      let minumuutus = [];
      let vastasemuutus = [];
      let minualgkoord = 0;
      let vastasealgkoord = 0;
      if (mina === "keskele") {
        minualguspunkt = [];
        minulopppunkt = [...minulaevadkeskel, ...minulaevadbaasis];
        minumuutus = [...minulaevadbaasis];
        minualgkoord = k.lako;
        setMinulaevadbaasis(minualguspunkt);
        setMinulaevadkeskel(minulopppunkt);
      }
      if (mina === "tagasi") {
        minualguspunkt = [];
        minulopppunkt = [...minulaevadbaasis, ...minulaevadkeskel];
        minumuutus = [...minulaevadkeskel];
        minualgkoord = k.keko;
        setMinulaevadbaasis(minulopppunkt);
        setMinulaevadkeskel(minualguspunkt);
      }
      if (vastane === "keskele") {
        vastasealguspunkt = [];
        vastaselopppunkt = [...vastaselaevadkeskel, ...vastaselaevadbaasis];
        vastasemuutus = [...vastaselaevadbaasis];
        vastasealgkoord = k.v_lako;
        setVastaselaevadbaasis(vastasealguspunkt);
        setVastaselaevadkeskel(vastaselopppunkt);
      }
      if (vastane === "tagasi") {
        vastasealguspunkt = [];
        vastaselopppunkt = [...vastaselaevadbaasis, ...vastaselaevadkeskel];
        vastasemuutus = [...vastaselaevadkeskel];
        vastasealgkoord = k.v_keko;
        setVastaselaevadbaasis(vastaselopppunkt);
        setVastaselaevadkeskel(vastasealguspunkt);
      }
      setAnimakaib({
        m_laevad: minumuutus,
        v_laevad: vastasemuutus,
        m_alguspunkt: minualgkoord,
        v_alguspunkt: vastasealgkoord,
      });
    }
    function lahing(
      minakaitses = false,
      vastanekaitses = false,
      minakeskel = false,
      vastanekeskel = false,
      minabaasis = false,
      vastanebaasis = false,
      vajavoitjat = false
    ) {
      let mk = [...minukaitse];
      let vk = [...vastasekaitse];
      let mlk = [...minulaevadkeskel];
      let vlk = [...vastaselaevadkeskel];
      let mlb = [...minulaevadbaasis];
      let vlb = [...vastaselaevadbaasis];
      let ml_kilp = 0;
      let vl_kilp = 0;
      let mb_kilp = 0;
      let vb_kilp = 0;
      let kokkuvote = {
        osalised: {},
        inikas: [],
        voitlus: [],
        tingimused: [
          minakaitses,
          vastanekaitses,
          minakeskel,
          vastanekeskel,
          minabaasis,
          vastanebaasis,
          vajavoitjat,
        ],
        jarelliikumine: {},
      };
      if (minakaitses) {
        kokkuvote.osalised.minukaitse = [...minukaitse];
      }
      if (vastanekaitses) {
        kokkuvote.osalised.vastasekaitse = [...vastasekaitse];
      }
      if (minakeskel) {
        kokkuvote.osalised.minulaevadkeskel = [...minulaevadkeskel];
      }
      if (vastanekeskel) {
        kokkuvote.osalised.vastaselaevadkeskel = [...vastaselaevadkeskel];
      }
      if (minabaasis) {
        kokkuvote.osalised.minulaevadbaasis = [...minulaevadbaasis];
      }
      if (vastanebaasis) {
        kokkuvote.osalised.vastaselaevadbaasis = [...vastaselaevadbaasis];
      }
      function initsiatiiv() {
        function lahingu_alguse_jarjestus(arr) {
          let seguarr = [...arr];
          for (let i = seguarr.length - 1; i > 0; i--) {
            let kaalud = i + 1;
            for (let k = 0; k <= i; k++) {
              kaalud += lookup(seguarr[k].tyyp).klass === 1 ? 2 : 1; //kaitseehitised saavad eelise
            }
            let juhuIndex = suva(kaalud);
            let valitudIndex = i;

            for (let k = 0; k <= i; k++) {
              juhuIndex -= lookup(seguarr[k].tyyp).klass === 1 ? 2 : 1;
              if (juhuIndex < k) {
                valitudIndex = k;
                break;
              }
            }
            [seguarr[i], seguarr[valitudIndex]] = [
              seguarr[valitudIndex],
              seguarr[i],
            ];
          }
          if (minakaitses) {
            console.log("mina olen kaitses");
            seguarr.unshift({
              tyyp: kaardid.emalaevastatid.tyyp,
              hp: minuhp,
              vastane: false,
            });
          } else if (vastanekaitses) {
            console.log("vastane on kaitses");
            seguarr.unshift({
              tyyp: kaardid.emalaevastatid.tyyp,
              hp: vastasehp,
              vastane: true,
            });
          }

          return seguarr;
        }

        let arr = [
          ...(minakaitses && joud.m_kaitse > 0 ? minukaitse : []),
          ...(vastanekaitses && joud.v_kaitse > 0 ? vastasekaitse : []),
          ...(minakeskel && joud.m_keskel > 0 ? minulaevadkeskel : []),
          ...(vastanekeskel && joud.v_keskel > 0 ? vastaselaevadkeskel : []),
          ...(minabaasis && joud.m_baasis > 0 ? minulaevadbaasis : []),
          ...(vastanebaasis && joud.v_baasis > 0 ? vastaselaevadbaasis : []),
        ].filter((a) => a !== undefined);
        return lahingu_alguse_jarjestus(arr);
      }
      function kilp() {
        inikas.forEach((a) =>
          a.tyyp === 9 && a.vastane ? (vl_kilp += 2) : ""
        );
        inikas.forEach((a) =>
          a.tyyp === 13 && a.vastane ? (vb_kilp += 3) : ""
        );
        inikas.forEach((a) =>
          a.tyyp === 9 && a.vastane === false ? (ml_kilp += 2) : ""
        );
        inikas.forEach((a) =>
          a.tyyp === 13 && a.vastane === false ? (mb_kilp += 3) : ""
        );
        console.log("kilbid");
        console.log(ml_kilp, vl_kilp, mb_kilp, vb_kilp);
      }
      function vali_sihtmark(laskja, index) {
        function rynnak(laskja, sihtmark) {
          const damm = lookup(laskja.tyyp).rynnak;
          let puru = false;
          if (sihtmark.nimi !== "baasikilp" && sihtmark.nimi !== "laevakilp") {
            const sihtmargiIndex = inikas.findIndex(
              (a) => a.id === sihtmark.id
            );
            inikas[sihtmargiIndex].hp -= damm;
            if (inikas[sihtmargiIndex].hp < 1) {
              puru = true;
            }
            console.log(
              (laskja.vastane ? "vastase " : "sinu ") +
                lookup(laskja.tyyp).nimi +
                " ründas " +
                (sihtmark.vastane ? "vastase " : "sinu ") +
                lookup(sihtmark.tyyp).nimi +
                (puru ? " ja purustas selle." : ".")
            );
          } else {
            if (sihtmark.nimi === "baasikilp" && laskja.vastane) {
              mb_kilp -= damm;
              if (mb_kilp <= 0) {
                puru = true;
              }
            } else if (
              sihtmark.nimi === "baasikilp" &&
              laskja.vastane === false
            ) {
              vb_kilp -= damm;
              if (vb_kilp <= 0) {
                puru = true;
              }
            } else if (sihtmark.nimi === "laevakilp" && laskja.vastane) {
              ml_kilp -= damm;
              if (ml_kilp <= 0) {
                puru = true;
              }
            } else if (
              sihtmark.nimi === "laevakilp" &&
              laskja.vastane === false
            ) {
              vl_kilp -= damm;
              if (vl_kilp <= 0) {
                puru = true;
              }
            }
            console.log(
              (laskja.vastane ? "vastase " : "sinu ") +
                lookup(laskja.tyyp).nimi +
                " ründas " +
                (sihtmark.vastane ? "vastase " : "sinu ") +
                sihtmark.nimi +
                "i" +
                (puru ? " ja purustas selle." : ".")
            );
          }
          kokkuvote.voitlus.push({
            laskja: laskja,
            sihtmark: sihtmark,
            puru: puru,
          });
        }
        function sihtmargiotsing(laskja, laskja_init) {
          function kasOnVarg(laskja_init) {
            return sihtmargid2.filter(
              (a) =>
                ((a.tyyp === 5 || a.tyypF === 7) &&
                  inikas.findIndex((b) => b.id === a.id) > laskja_init) ===
                false
            );
          }
          let sihtmargid1 = inikas.filter(
            (a) =>
              (laskja.vastane ? a.vastane === false : a.vastane === true) &&
              a.hp > 0 &&
              a !== undefined
          );
          let sihtmargid2 = [];
          let sihtmargid3 = [];
          if (laskja.tyyp === 0) {
            if (laskja.vastane && ml_kilp > 0) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: false }];
            } else if (laskja.vastane === false && vl_kilp > 0) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: true }];
            } else sihtmargid2 = [...sihtmargid1];
          }
          if (lookup(laskja.tyyp).klass === 1) {
            if (laskja.vastane && ml_kilp > 0) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: false }];
            } else if (laskja.vastane === false && vl_kilp > 0) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: true }];
            } else sihtmargid2 = [...sihtmargid1];
          }
          if (lookup(laskja.tyyp).klass === 2) {
            if (laskja.vastane && ml_kilp > 0 && mb_kilp <= 0) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: false }];
            } else if (
              laskja.vastane === false &&
              vl_kilp > 0 &&
              vb_kilp <= 0
            ) {
              sihtmargid2 = [{ nimi: "laevakilp", vastane: true }];
            } else if (laskja.vastane && ml_kilp <= 0 && mb_kilp > 0) {
              sihtmargid2 = [{ nimi: "baasikilp", vastane: "false" }];
            } else if (
              laskja.vastane === false &&
              vl_kilp <= 0 &&
              vb_kilp > 0
            ) {
              sihtmargid2 = [{ nimi: "baasikilp", vastane: "true" }];
            } else if (laskja.vastane && ml_kilp > 0 && mb_kilp > 0) {
              sihtmargid2 = [
                { nimi: "baasikilp", vastane: false },
                { nimi: "laevakilp", vastane: false },
              ];
            } else if (laskja.vastane === false && vl_kilp > 0 && vb_kilp > 0) {
              sihtmargid2 = [
                { nimi: "baasikilp", vastane: true },
                { nimi: "laevakilp", vastane: true },
              ];
            } else if (
              sihtmargid1.some((b) => lookup(b.tyyp).klass === 1) ||
              sihtmargid1.some((b) => lookup(b.tyyp).klass === 2)
            ) {
              sihtmargid2 = sihtmargid1.filter(
                (c) =>
                  lookup(c.tyyp).klass === 1 ||
                  lookup(c.tyyp).klass === 2 ||
                  c.tyyp === 9 ||
                  c.tyyp === 13
              );
            } else if (
              sihtmargid1.some((b) => lookup(b.tyyp).klass === 3) ||
              sihtmargid1.some((b) => lookup(b.tyyp).klass === 5)
            ) {
              sihtmargid2 = sihtmargid1.filter(
                (c) => lookup(c.tyyp).klass === 3 || lookup(c.tyyp).klass === 5
              );
            } else {
              sihtmargid2 = [...sihtmargid1];
            }
          }
          if (lookup(laskja.tyyp).klass === 3) {
            if (laskja.vastane && mb_kilp > 0) {
              sihtmargid2 = [{ nimi: "baasikilp" }];
            } else if (laskja.vastane === false && vb_kilp > 0) {
              sihtmargid2 = [{ nimi: "baasikilp" }];
            } else
              sihtmargid2 = sihtmargid1.filter(
                (c) => lookup(c.tyyp).klass === 0 || lookup(c.tyyp).klass === 1
              );
          }
          sihtmargid3 = kasOnVarg(laskja_init);
          //if (sihtmargid3.length > 0) {
          //  console.log(
          //    (laskja.vastane ? "vastase " : "sinu ") +
          //      lookup(laskja.tyyp).nimi +
          //      " sihtmärgid:"
          //  );
          //  console.log(sihtmargid3);
          //} else {
          //  console.log(
          //    (laskja.vastane ? "vastase " : "sinu ") +
          //      lookup(laskja.tyyp).nimi +
          //      " ei leidnud sihtmärke"
          //  );
          //}
          return segamine([...sihtmargid3])[0];
        }

        const sihtmark = sihtmargiotsing(laskja, index);
        if (sihtmark !== undefined) {
          rynnak(laskja, sihtmark);
        } else {
          return "SIHTMÄRK UNDEFINED";
        }
      }
      let inikas = initsiatiiv();
      let minuhaps = minuhp;
      let vastasehaps = vastasehp;
      kokkuvote.inikas = [...inikas];
      kilp();
      console.log("inikas enne lahingut");
      console.log([...inikas]);
      inikas.forEach((a, index) =>
        a.hp > 0 && lookup(a.tyyp).rynnak > 0 ? vali_sihtmark(a, index) : ""
      );
      function j2relarvestus(
        minakaitses = false,
        vastanekaitses = false,
        minakeskel = false,
        vastanekeskel = false,
        minabaasis = false,
        vastanebaasis = false,
        vajavoitjat = false,
        inikas
      ) {
        let kaotused = [];
        function remont() {
          if (inikas.some((a) => a.tyyp === 11 && a.hp > 0 && a.vastane)) {
            inikas
              .filter(
                (b) =>
                  b.hp > 0 &&
                  b.hp < lookup(b.tyyp).kaitse &&
                  b.vastane &&
                  (lookup(b.tyyp).klass === 2 ||
                    lookup(b.tyyp).klass === 3 ||
                    lookup(b.tyyp).klass === 5)
              )
              .forEach((c) => {
                c.hp = lookup(c.tyyp).kaitse;
                console.log(
                  "Vastase remondilaev parandas tema " +
                    lookup(c.tyyp).nimi +
                    " ära."
                );
              });
          }
          if (
            inikas.some((a) => a.tyyp === 11 && a.hp > 0 && a.vastane === false)
          ) {
            inikas
              .filter(
                (b) =>
                  b.hp > 0 &&
                  b.hp < lookup(b.tyyp).kaitse &&
                  b.vastane === false &&
                  (lookup(b.tyyp).klass === 2 ||
                    lookup(b.tyyp).klass === 3 ||
                    lookup(b.tyyp).klass === 5)
              )
              .forEach((c) => {
                //console.log(c);
                c.hp = lookup(c.tyyp).kaitse;
                console.log(
                  "Sinu remondilaev parandas sinu " +
                    lookup(c.tyyp).nimi +
                    " ära."
                );
              });
          }
        }
        function relsid_cooldown() {
          inikas.forEach((a) =>
            (a.tyyp === 6 || a.tyyp === 12) && a.hp > 0
              ? (a.voitles = true)
              : ""
          );
        }
        function eemalda(a, index) {
          if (a.tyyp === 0) {
            a.vastane ? (vastasehaps = a.hp) : (minuhaps = a.hp);
            return;
          }
          let eemaldatav = 0;
          if (mlb.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = mlb.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              mlb[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(mlb[eemaldatav]);
              mlb.splice(eemaldatav, 1);
            }
          }
          if (vlb.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = vlb.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              vlb[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(vlb[eemaldatav]);
              vlb.splice(eemaldatav, 1);
            }
          }
          if (mk.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = mk.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              mk[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(mk[eemaldatav]);
              mk.splice(eemaldatav, 1);
            }
          }
          if (vk.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = vk.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              vk[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(vk[eemaldatav]);
              vk.splice(eemaldatav, 1);
            }
          }
          if (mlk.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = mlk.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              mlk[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(mlk[eemaldatav]);
              mlk.splice(eemaldatav, 1);
            }
          }
          if (vlk.findIndex((b) => a.id === b.id) > -1) {
            eemaldatav = vlk.findIndex((b) => a.id === b.id);
            if (inikas[index].hp > 0) {
              vlk[eemaldatav].hp = inikas[index].hp;
            } else {
              kaotused.push(vlk[eemaldatav]);
              vlk.splice(eemaldatav, 1);
            }
          }
        }
        remont();
        relsid_cooldown();
        inikas.forEach((a, index) => eemalda(a, index));
        console.log("LAHING ON LÄBI.");
        if (inikas.filter((a) => a.hp > 0 && a.vastane).length === 0) {
          console.log("VASTASE LAEVASTIK PURUSTATI TÄIELIKULT");
        }
        if (
          inikas.filter((a) => a.hp > 0 && a.vastane === false).length === 0
        ) {
          console.log("SINU LAEVASTIK PURUSTATI TÄIELIKULT");
        }
        console.log("");
        console.log("SINU KAOTUSED: ");
        if (kaotused.some((a) => a.vastane === false)) {
          kaotused.map((a) =>
            a.vastane === false ? console.log(lookup(a.tyyp).nimi) : ""
          );
        } else console.log("Kaotusi polnud");
        console.log("VASTASE KAOTUSED: ");
        if (kaotused.some((a) => a.vastane)) {
          kaotused.map((a) =>
            a.vastane ? console.log(lookup(a.tyyp).nimi) : ""
          );
        } else console.log("Vastasel kaotusi polnud");
        if (vajavoitjat) {
          let voitja = "";
          if (inikas.filter((a) => a.hp > 0 && a.vastane).length === 0) {
            voitja = "mina";
          } else if (
            inikas.filter((a) => a.hp > 0 && a.vastane === false).length === 0
          ) {
            voitja = "vastane";
          } else if (
            kaotused.filter((a) => a.vastane === false).length >
            kaotused.filter((a) => a.vastane).length
          ) {
            voitja = "vastane";
          } else if (
            kaotused.filter((a) => a.vastane === false).length <
            kaotused.filter((a) => a.vastane).length
          ) {
            voitja = "mina";
          } else if (
            inikas.filter(
              (a) => a.hp > 0 && a.vastane && lookup(a.tyyp).klass === 2
            ).length >
            inikas.filter(
              (a) =>
                a.hp > 0 && a.vastane === false && lookup(a.tyyp).klass === 2
            ).length
          ) {
            voitja = "vastane";
          } else if (
            inikas.filter(
              (a) => a.hp > 0 && a.vastane && lookup(a.tyyp).klass === 2
            ).length <
            inikas.filter(
              (a) =>
                a.hp > 0 && a.vastane === false && lookup(a.tyyp).klass === 2
            ).length
          ) {
            voitja = "mina";
          } else if (
            inikas.filter((a) => a.hp > 0 && a.vastane).length >
            inikas.filter((a) => a.hp > 0 && a.vastane === false).length
          ) {
            voitja = "vastane";
          } else if (
            inikas.filter((a) => a.hp > 0 && a.vastane).length <
            inikas.filter((a) => a.hp > 0 && a.vastane === false).length
          ) {
            voitja = "mina";
          }
          if (voitja === "mina") {
            if (vastanekeskel) {
              if (vlk.length > 0) {
                vlb = [...vlb, ...vlk];
                vlk = [];
              }
              mlk = [...mlb];
              mlb = [];
              console.log(
                "SA SUUTSID KESKKOHA VASTASELT ÄRA VALLUTADA JA SUNDISID TA TAANDUMA."
              );
              kokkuvote.lopppunktid = { mina: "mlk", vastane: "vlb" };
            }
            if (
              minakeskel ||
              (vastanekeskel === false && minakeskel === false)
            ) {
              if (minabaasis) {
                mlk = [...mlk, ...mlb];
                mlb = [];
              }
              console.log("SA SUUTSID KESKKOHTA KAITSTA.");
              kokkuvote.lopppunktid = { mina: "mlk", vastane: "vlb" };
            }
          }
          if (voitja === "vastane") {
            if (minakeskel) {
              if (mlk.length > 0) {
                mlb = [...mlb, ...mlk];
                mlk = [];
              }
              vlk = [...vlb];
              vlb = [];
              console.log(
                "VASTANE SUUTIS SINULT KESKKOHA VALLUTADA NING SUNDIS SU TAANDUMA."
              );
              kokkuvote.lopppunktid = { mina: "mlb", vastane: "vlk" };
            }
            if (
              vastanekeskel ||
              (vastanekeskel === false && minakeskel === false)
            ) {
              if (vastanebaasis) {
                vlk = [...vlk, ...vlb];
                vlb = [];
              }
              console.log("VASTANE SUUTIS KESKKOHTA KAITSTA.");
              kokkuvote.lopppunktid = { mina: "mlb", vastane: "vlk" };
            }
          }
          if (voitja !== "vastane" && voitja !== "mina") {
            console.log(
              "Kindlat võitjat polnud, mõlemad pooled taandusid endistele positsioonidele."
            );
          }
        }
      }
      function utiliseerimine() {
        let minukasum = 0;
        let vastasekasum = 0;
        let minulaevumaas = inikas.filter(
          (a) => a.hp <= 0 && a.vastane === false
        ).length;
        let vastaselaevumaas = inikas.filter(
          (a) => a.hp <= 0 && a.vastane
        ).length;
        if (
          inikas.some((a) => a.tyyp === 10 && a.hp > 0 && a.vastane) &&
          inikas.some((a) => a.tyyp === 10 && a.hp > 0 && a.vastane === false)
        ) {
          minukasum = Math.floor(minulaevumaas / 3);
          vastasekasum = Math.floor(vastaselaevumaas / 3);
        } else if (inikas.some((a) => a.tyyp === 10 && a.hp > 0 && a.vastane)) {
          vastasekasum = Math.floor((minulaevumaas + vastaselaevumaas) / 3);
        } else if (
          inikas.some((a) => a.tyyp === 10 && a.hp > 0 && a.vastane === false)
        ) {
          minukasum = Math.floor((minulaevumaas + vastaselaevumaas) / 3);
        }
        if (minukasum > 0) {
          console.log(
            "Sinu utiliseerijad kogusid lahinguväljalt " +
              minukasum +
              " mana väärtuses vanarauda."
          );
        }
        if (vastasekasum > 0) {
          console.log(
            "Vastase utiliseerijad kogusid lahinguväljalt " +
              vastasekasum +
              " mana väärtuses vanarauda."
          );
        }
        setMinumana(minumana + minukasum);
        setVastasemana(vastasemana + vastasekasum);
      }
      lahingRef.current = kokkuvote;
      console.log("käivitub järelarvestus");
      console.log(lahingRef.current.tingimused, lahingRef.current.inikas);
      j2relarvestus(...lahingRef.current.tingimused, lahingRef.current.inikas);
      const voitlus = lahingRef.current.voitlus;
      let kestus = anima_kestus * 2;
      for (let i in voitlus) {
        kestus += anima_kestus + pausi_kestus;
        if (voitlus[i].puru) {
          if (voitlus[i].sihtmark.tyyp !== 0) {
            kestus += puru_kestus;
          } else {
            kestus += puru_kestus * 6;
            kestus -= anima_kestus
            break;
          }
        }
      }
      setTimeout(() => {
        lahingRef.current = null;
        console.log("test");
        utiliseerimine();
        setMinulaevadbaasis(mlb);
        setVastaselaevadbaasis(vlb);
        setMinulaevadkeskel(mlk);
        setVastaselaevadkeskel(vlk);
        setMinukaitse(mk);
        setVastasekaitse(vk);
        setMinuhp(minuhaps);
        setVastasehp(vastasehaps);
        setLahingujargne(lahingujargne + 1);
      }, kestus);
      setLahinganima(kestus);
    }

    if (edasiliikuda && v_edasiliikuda && joud.v_keskel > 0) {
      console.log(
        "LAHING: sina ründad vastase käes olevat keskpaika, kuhu saabuvad tema abiväed"
      );
      lahing(false, false, false, true, true, true, true);
    } else if (edasiliikuda && v_edasiliikuda && joud.m_keskel > 0) {
      console.log(
        "LAHING: vastane ründab sinu käes olevat keskpaika, kuhu saabuvad sinu abiväed"
      );
      lahing(false, false, true, false, true, true, true);
    } else if (edasiliikuda && joud.v_keskel > 0 && v_rynnata === true) {
      console.log(
        "LAHING: vastane ründab sinu emalaeva, kuid kohtub teel sinu vägedega"
      );
      lahing(false, false, false, true, true, false);
    } else if (
      edasiliikuda &&
      v_edasiliikuda === false &&
      joud.v_keskel > 0 &&
      v_taanduda === false &&
      v_rynnata === false
    ) {
      console.log("LAHING: sina ründad keskpaigas olevaid vastase vägesid");
      lahing(false, false, false, true, true, false, true);
    } else if (v_edasiliikuda && joud.m_keskel > 0 && rynnata) {
      console.log(
        "LAHING: sina ründad vastase emalaeva, kuid kohtud teel vastase vägedega"
      );
      lahing(false, false, true, false, false, true);
    } else if (
      v_edasiliikuda &&
      edasiliikuda === false &&
      joud.m_keskel > 0 &&
      taanduda === false &&
      rynnata === false
    ) {
      console.log("LAHING: vastane ründab keskpaigas olevaid sinu vägesid");
      lahing(false, false, true, false, false, true, true);
    } else if (
      edasiliikuda &&
      v_edasiliikuda &&
      joud.v_keskel === 0 &&
      joud.m_keskel === 0
    ) {
      console.log(
        "LAHING: sinu ja vastase väed jõuavad mõlemad samal ajal keskpaika"
      );
      lahing(false, false, false, false, true, true, true);
    } else if (rynnata && v_edasiliikuda === false) {
      console.log("LAHING: sina ründad vastase baasi");
      lahing(false, true, true, false, false, true);
    } else if (v_rynnata && edasiliikuda === false) {
      console.log("LAHING: vastane ründab sinu baasi");
      lahing(true, false, false, true, true, false);
    } else if (
      edasiliikuda &&
      joud.v_keskel === 0 &&
      v_edasiliikuda === false &&
      joud.m_keskel === 0
    ) {
      console.log("LIIKUMINE: sina võtad keskpaiga üle");
      liigu("keskele");
    } else if (
      v_edasiliikuda &&
      joud.m_keskel === 0 &&
      edasiliikuda === false &&
      joud.v_keskel === 0
    ) {
      console.log("LIIKUMINE: vastane võtab keskpaiga üle");
      liigu("", "keskele");
    } else if (edasiliikuda && joud.v_keskel > 0 && v_taanduda) {
      console.log(
        "LIIKUMINE: vastane taandub keskelt, sina võtad keskpaiga üle"
      );
      liigu("keskele", "tagasi");
    } else if (v_edasiliikuda && joud.m_keskel > 0 && taanduda) {
      console.log(
        "LIIKUMINE: sina taandud keskelt, vastane võtab keskpaiga üle"
      );
      liigu("tagasi", "keskele");
    } else if (joud.v_keskel > 0 && v_taanduda) {
      console.log("LIIKUMINE: vastane taandub keskelt");
      liigu("", "tagasi");
    } else if (joud.m_keskel > 0 && taanduda) {
      console.log("LIIKUMINE: sina taandud keskelt");
      liigu("tagasi");
    } else if (edasiliikuda && joud.m_keskel > 0 && v_edasiliikuda === false) {
      console.log("LIIKUMINE: sina täiendad oma vägesid keskpaigas");
      liigu("keskele");
    } else if (v_edasiliikuda && joud.v_keskel > 0 && edasiliikuda === false) {
      console.log("LIIKUMINE: vastane täiendab oma vägesid keskpaigas");
      liigu("", "keskele");
    } else if (
      edasiliikuda === false &&
      v_edasiliikuda === false &&
      rynnata === false &&
      v_rynnata === false &&
      taanduda === false &&
      v_taanduda === false
    ) {
      console.log("MIDAGI EI TOIMU: kõik jäävad positsioonidele");
      setLahingujargne(lahingujargne + 1);
    }
  }

  function relsid() {
    let mlb = [...minulaevadbaasis];
    let vlb = [...vastaselaevadbaasis];
    let mk = [...minukaitse];
    let vk = [...vastasekaitse];
    let mlk = [...minulaevadkeskel];
    let vlk = [...vastaselaevadkeskel];
    let arr = [mlb, vlb, mk, vk, mlk, vlk];
    let relss_sihtmark = {};
    let relss_minuhp = minuhp;
    let relss_vastasehp = vastasehp;
    function relss_sihtimine(i, j) {
      if (i === 0) {
        if (arr[i][j].voitles === true) {
          console.log(
            "Sinu relsiheitja osales juba lahingus ja ei saa kohe uuesti lasta."
          );
        } else {
          if (vlk.length > 0) {
            relss_sihtmark = suva(vlk.length);
            vlk[relss_sihtmark].hp -= lookup(arr[i][j].tyyp).rynnak;

            console.log(
              "Minu relsiheitja tulistas vastase " +
                lookup(vlk[relss_sihtmark].tyyp).nimi +
                (vlk[relss_sihtmark].hp < 1 ? " ning hävitas selle." : ".")
            );
            if (vlk[relss_sihtmark].hp < 1) {
              vlk.splice(relss_sihtmark, 1);
            }
          } else console.log("Minu relsiheitja ei leidnud sobivat sihtmärki.");
        }
        mlb[j].voitles = false;
      }
      if (i === 1) {
        if (arr[i][j].voitles === true) {
          console.log(
            "Vastase relsiheitja osales juba lahingus ja ei saa kohe uuesti lasta."
          );
        } else {
          if (mlk.length > 0) {
            relss_sihtmark = suva(mlk.length);
            mlk[relss_sihtmark].hp -= lookup(arr[i][j].tyyp).rynnak;

            console.log(
              "vastase relsiheitja tulistas minu " +
                lookup(mlk[relss_sihtmark].tyyp).nimi +
                (mlk[relss_sihtmark].hp < 1 ? " ning hävitas selle." : ".")
            );
            if (mlk[relss_sihtmark].hp < 1) {
              mlk.splice(relss_sihtmark, 1);
            }
          } else
            console.log("Vastase relsiheitja ei leidnud sobivat sihtmärki.");
          vlb[j].voitles = false;
        }
        if (i === 2) {
          if (arr[i][j].voitles === true) {
            console.log(
              "Sinu relsskahur osales juba lahingus ja ei saa kohe uuesti lasta."
            );
          } else {
            if (vlk.length > 0) {
              relss_sihtmark = suva(vlk.length);
              vlk[relss_sihtmark].hp -= lookup(arr[i][j].tyyp).rynnak;

              console.log(
                "Minu relsskahur tulistas vastase " +
                  lookup(vlk[relss_sihtmark].tyyp).nimi +
                  (vlk[relss_sihtmark].hp < 1 ? " ning hävitas selle." : ".")
              );
              if (vlk[relss_sihtmark].hp < 1) {
                vlk.splice(relss_sihtmark, 1);
              }
            } else console.log("Minu relsskahur ei leidnud sobivat sihtmärki.");
          }
          mk[j].voitles = false;
        }
      }
      if (i === 3) {
        if (arr[i][j].voitles === true) {
          console.log(
            "Vastase relsskahur osales juba lahingus ja ei saa kohe uuesti lasta."
          );
        } else {
          if (mlk.length > 0) {
            relss_sihtmark = suva(mlk.length);
            mlk[relss_sihtmark].hp -= lookup(arr[i][j].tyyp).rynnak;

            console.log(
              "vastase relsskahur tulistas minu " +
                lookup(mlk[relss_sihtmark].tyyp).nimi +
                (mlk[relss_sihtmark].hp < 1 ? " ning hävitas selle." : ".")
            );
            if (mlk[relss_sihtmark].hp < 1) {
              mlk.splice(relss_sihtmark, 1);
            }
          } else
            console.log("Vastase relsskahur ei leidnud sobivat sihtmärki.");
        }
        vk[j].voitles = false;
      }
      if (i === 4) {
        if (arr[i][j].voitles === true) {
          console.log(
            "Sinu relsiheitja osales juba lahingus ja ei saa kohe uuesti lasta."
          );
        } else {
          relss_vastasehp -= lookup(arr[i][j].tyyp).rynnak;
          console.log("Minu relsiheitja tulistas vastase emalaeva.");
        }
        mlk[j].voitles = false;
      }
      if (i === 5) {
        if (arr[i][j].voitles === true) {
          console.log(
            "Vastase relsiheitja osales juba lahingus ja ei saa kohe uuesti lasta."
          );
        } else {
          relss_minuhp -= lookup(arr[i][j].tyyp).rynnak;
          console.log("Vastase relsiheitja tulistas minu emalaeva.");
        }
        vlk[j].voitles = false;
      }
    }

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        if (arr[i][j].tyyp === 6 || arr[i][j].tyyp === 12) {
          relss_sihtimine(i, j);
        }
      }
    }
    setMinulaevadbaasis(mlb);
    setVastaselaevadbaasis(vlb);
    setMinulaevadkeskel(mlk);
    setVastaselaevadkeskel(vlk);
    setMinuhp(relss_minuhp);
    setVastasehp(relss_vastasehp);
    setRelsidkainud(relsidkainud + 1);
  }

  function taandu() {
    if (vaba) {
      if (minulaevadkeskel.length > 0) {
        taanduda = true;
        setMinukaiklabi(kaik);
      } else {
        console.log("ei saa taanduda");
      }
    }
  }

  function jaapaigale() {
    if (vaba) {
      setMinukaiklabi(kaik);
    }
  }

  function liiguedasi() {
    const joud = j6ud();
    if (vaba) {
      if (joud.m_baasis === 0) {
        console.log("pole laevu, mida edasi liigutada");
      } else if (joud.v_keskel > 0 && joud.m_havitajaid === 0) {
        console.log("sul pole hävitajaid, et vastast rünnata");
      } else {
        edasiliikuda = true;
        setMinukaiklabi(kaik);
      }
    }
  }

  function rynda() {
    if (vaba) {
      if (minulaevadkeskel.length > 0) {
        rynnata = true;
        setMinukaiklabi(kaik);
      } else {
        console.log("ei saa rünnata");
      }
    }
  }

  function joonistamine() {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, k.c_laius, k.c_korgus);
      context.drawImage(kosmosRef.current, 0, 0, k.c_laius, k.c_korgus);
      if (manglabi.current !== true) {
        function joonista(
          pilt,
          spriteX,
          spriteY,
          spriteLaius,
          spriteKorgus,
          x,
          y,
          laius,
          korgus
        ) {
          if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context.drawImage(
              pilt,
              spriteX,
              spriteY,
              spriteLaius,
              spriteKorgus,
              x,
              y,
              laius,
              korgus
            );
          }
        }
        if (nodraw.current !== "minuemalaev")
          context.drawImage(
            emalaevRef.current,
            0,
            0,
            k.emalaevalaius,
            k.emalaevakorgus,
            k.minuemalaevx,
            k.emalaevy,
            k.emalaevalaius,
            k.emalaevakorgus
          );
        if (nodraw.current !== "vastaseemalaev")
          context.drawImage(
            emalaevRef.current,
            k.emalaevalaius,
            0,
            k.emalaevalaius,
            k.emalaevakorgus,
            k.vastaseemalaevx,
            k.emalaevy,
            k.emalaevalaius,
            k.emalaevakorgus
          );
        function canvasele(algmassiiv, vastane = false) {
          const massiiv = algmassiiv.filter((a) => a !== undefined);
          if (
            algmassiiv === minulaevadbaasis ||
            algmassiiv === vastaselaevadbaasis ||
            algmassiiv === minulaevadkeskel ||
            algmassiiv === vastaselaevadkeskel
          ) {
            massiiv.map((a, index) => {
              if (
                animeerida.m_laevad.some((b) => b.id === a.id) === false &&
                animeerida.v_laevad.some((b) => b.id === a.id) === false &&
                (lahingRef.current === null ||
                  lahingRef.current.inikas.some((b) => b.id === a.id) === false)
              ) {
                joonista(
                  spriteRef.current,
                  vastane
                    ? lookup(a.tyyp).pilt * 100 + 100
                    : lookup(a.tyyp).pilt * 100,
                  0,
                  100,
                  100,
                  vastane
                    ? algmassiiv === vastaselaevadkeskel
                      ? k.v_keko[index].x
                      : a.baasiees
                      ? k.v_baasirynne[index].x
                      : a.teepeal
                      ? k.v_teelkohtumine[index].x
                      : k.v_lako[index].x
                    : algmassiiv === minulaevadkeskel
                    ? k.keko[index].x
                    : a.baasiees
                    ? k.baasirynne[index].x
                    : a.teepeal
                    ? k.teelkohtumine[index].x
                    : k.lako[index].x,
                  k.lako[index].y,
                  k.ruut,
                  k.ruut
                );
              }
            });
          } else {
            massiiv.map((a, index) => {
              if (
                lahingRef.current === null ||
                lahingRef.current.inikas.some((b) => b.id === a.id) === false
              ) {
                joonista(
                  spriteRef.current,
                  vastane
                    ? lookup(a.tyyp).pilt * 100 + 100
                    : lookup(a.tyyp).pilt * 100,
                  0,
                  100,
                  100,
                  vastane ? k.v_kako[index].x : k.kako[index].x,
                  k.kako[index].y,
                  k.ruut,
                  k.ruut
                );
              }
            });
          }
        }
        if (minulaevadbaasis.length > 0) {
          canvasele(minulaevadbaasis);
        }
        if (vastaselaevadbaasis.length > 0) {
          canvasele(vastaselaevadbaasis, true);
        }
        if (minulaevadkeskel.length > 0) {
          canvasele(minulaevadkeskel);
        }
        if (vastaselaevadkeskel.length > 0) {
          canvasele(vastaselaevadkeskel, true);
        }
        if (minukaitse.length > 0) {
          canvasele(minukaitse);
        }
        if (vastasekaitse.length > 0) {
          canvasele(vastasekaitse, true);
        }
        function plahvatus(x, y, suurus, algusaeg, kestus = 500, kilp = false) {
          if (anima[1] <= algusaeg && algusaeg - anima[1] < kestus) {
            const tsykkel = kestus / intervall; //10
            const nyyd = (algusaeg - anima[1]) / intervall;
            const raadius = 0 + (suurus / tsykkel) * nyyd;
            if (kilp) {
              context.globalAlpha = 0.5;
              context.beginPath();
              context.fillStyle = "blue";
              context.arc(x, y, raadius, 0, 2 * Math.PI);
              context.fill();
              context.globalAlpha = 1;
            } else {
              context.beginPath();
              context.fillStyle = "red";
              context.arc(x, y, raadius, 0, 2 * Math.PI);
              context.fill();
              context.globalAlpha = 0 + (1 / tsykkel) * nyyd;
              context.beginPath();
              context.fillStyle = "yellow";
              context.arc(x, y, raadius, 0, 2 * Math.PI);
              context.fill();
              context.globalAlpha = 1;
            }
          }
        }
        function laserkiir(algus_x, algus_y, lopp_x, lopp_y, algusaeg, kestus) {
          if (anima[1] <= algusaeg && algusaeg - anima[1] < kestus) {
            context.beginPath();
            context.strokeStyle = "white";
            context.lineWidth = 2;
            context.moveTo(algus_x, algus_y);
            context.lineTo(lopp_x, lopp_y);
            context.stroke();
          }
        }
        function mismassiiv(i) {
          if (minulaevadbaasis.some((a) => a.id === i)) {
            return k.lako[minulaevadbaasis.findIndex((a) => a.id === i)];
          }
          if (vastaselaevadbaasis.some((a) => a.id === i)) {
            return k.v_lako[vastaselaevadbaasis.findIndex((a) => a.id === i)];
          }
          if (minulaevadkeskel.some((a) => a.id === i)) {
            return k.keko[minulaevadkeskel.findIndex((a) => a.id === i)];
          }
          if (vastaselaevadkeskel.some((a) => a.id === i)) {
            return k.v_keko[vastaselaevadkeskel.findIndex((a) => a.id === i)];
          }
          if (minukaitse.some((a) => a.id === i)) {
            return k.kako[minukaitse.findIndex((a) => a.id === i)];
          }
          if (vastasekaitse.some((a) => a.id === i)) {
            return k.v_kako[vastasekaitse.findIndex((a) => a.id === i)];
          }
        }
        if (animeerida.m_laevad.length > 0 || animeerida.v_laevad.length > 0) {
          let tsykkel = anima[0] / intervall;
          let praegu = (anima[0] - anima[1]) / intervall;
          let laev;
          let koord = {};
          let x_alguspunkt;
          let y_alguspunkt;
          let x;
          let y;
          let pildilisa = 100;
          let arr = [...animeerida.v_laevad];
          for (let i in arr) {
            laev = lookup(arr[i].tyyp);
            koord = mismassiiv(arr[i].id);
            x_alguspunkt =
              animeerida.v_alguspunkt === 0
                ? k.c_laius
                : animeerida.v_alguspunkt[i].x;
            x =
              koord.x < x_alguspunkt
                ? x_alguspunkt -
                  Math.ceil(
                    (Math.abs(x_alguspunkt - koord.x) / tsykkel) * praegu
                  )
                : x_alguspunkt +
                  Math.ceil(
                    (Math.abs(x_alguspunkt - koord.x) / tsykkel) * praegu
                  );
            y_alguspunkt =
              animeerida.v_alguspunkt === 0
                ? koord.y
                : animeerida.v_alguspunkt[i].y;

            y =
              koord.y < y_alguspunkt
                ? y_alguspunkt -
                  Math.ceil(
                    (Math.abs(y_alguspunkt - koord.y) / tsykkel) * praegu
                  )
                : y_alguspunkt +
                  Math.ceil(
                    (Math.abs(y_alguspunkt - koord.y) / tsykkel) * praegu
                  );
            joonista(
              spriteRef.current,
              koord.x > x_alguspunkt
                ? laev.pilt * 100
                : laev.pilt * 100 + pildilisa,
              0,
              100,
              100,
              x,
              y,
              k.ruut,
              k.ruut
            );
          }
          arr = [...animeerida.m_laevad];
          for (let i in arr) {
            laev = lookup(arr[i].tyyp);
            koord = mismassiiv(arr[i].id);
            x_alguspunkt =
              animeerida.m_alguspunkt === 0
                ? 0 - k.ruut
                : animeerida.m_alguspunkt[i].x;
            x =
              koord.x > x_alguspunkt
                ? x_alguspunkt +
                  Math.ceil(
                    (Math.abs(x_alguspunkt - koord.x) / tsykkel) * praegu
                  )
                : x_alguspunkt -
                  Math.ceil(
                    (Math.abs(x_alguspunkt - koord.x) / tsykkel) * praegu
                  );
            y_alguspunkt =
              animeerida.m_alguspunkt === 0
                ? koord.y
                : animeerida.m_alguspunkt[i].y;
            y =
              koord.y > y_alguspunkt
                ? y_alguspunkt +
                  Math.ceil(
                    (Math.abs(y_alguspunkt - koord.y) / tsykkel) * praegu
                  )
                : y_alguspunkt -
                  Math.ceil(
                    (Math.abs(y_alguspunkt - koord.y) / tsykkel) * praegu
                  );
            joonista(
              spriteRef.current,
              koord.x >= x_alguspunkt
                ? laev.pilt * 100
                : laev.pilt * 100 + pildilisa,
              0,
              100,
              100,
              x,
              y,
              k.ruut,
              k.ruut
            );
          }
        }
        if (lahingRef.current) {
          function kasajutarr(i) {
            if (m_ajutarr.some((a) => a.id === i)) {
              return m_ajutkoord[m_ajutarr.findIndex((a) => a.id === i)];
            }
            if (v_ajutarr.some((a) => a.id === i)) {
              return v_ajutkoord[v_ajutarr.findIndex((a) => a.id === i)];
            }
          }
          function kaslopparr(i) {
            if (m_lopparr.some((a) => a.id === i)) {
              return m_loppkoord[m_lopparr.findIndex((a) => a.id === i)];
            }
            if (v_lopparr.some((a) => a.id === i)) {
              return v_loppkoord[v_lopparr.findIndex((a) => a.id === i)];
            }
          }
          const ajakava = [];
          let inikas = [...lahingRef.current.inikas];
          let ting = lahingRef.current.tingimused;
          let voitlus = lahingRef.current.voitlus;
          let lopp = lahingRef.current.lopppunktid;
          voitlus.forEach((i, index) => {
            ajakava.push({
              algus:
                index === 0
                  ? anima[0] - anima_kestus
                  : ajakava[index - 1].lopp - pausi_kestus,
              puru: i.puru
                ? i.sihtmark.tyyp === 0
                  ? puru_kestus * 6
                  : puru_kestus
                : 0,
              get lopp() {
                return this.algus - anima_kestus - this.puru;
              },
            });
            if (i.puru && i.sihtmark.nimi === undefined) {
              //kui hävib lahingus, siis omadus "puruks" on selle animatsiooni aeg (anima[1])
              inikas[inikas.findIndex((a) => a.id === i.sihtmark.id)].puruks =
                ajakava[index].lopp;
            }
          });
          let tsykkel = anima_kestus / intervall;
          let praegu = (anima[0] - anima[1]) / intervall;
          let praegu2 = (anima_kestus - anima[1]) / intervall;
          let laev;
          let algkoord = {};
          let vahepeatus = {};
          let lopppeatus = {};
          let m_ajutkoord = [];
          let v_ajutkoord = [];
          let m_ajutarr = [];
          let v_ajutarr = [];
          let m_lopparr = inikas.filter(
            (a) => a.vastane === false && a.tyyp !== 0 && a.puruks === undefined
          );
          let m_loppkoord;
          let v_lopparr = inikas.filter(
            (a) => a.vastane && a.tyyp !== 0 && a.puruks === undefined
          );
          let v_loppkoord;
          let laskja = {};
          let sihtmark = {};
          let pildisuund;
          let x;
          let y;
          let pildilisa = 100;
          function tingid() {
            if (ting[0]) {
              v_ajutkoord = k.v_baasirynne;
              v_ajutarr = [...vastaselaevadkeskel];
              //console.log("mina kaitsen baasi");
            } else if (ting[1]) {
              m_ajutkoord = k.baasirynne;
              m_ajutarr = [...minulaevadkeskel];
              //console.log("vastane kaitseb baasi");
            } else if (ting[2] && ting[4] && ting[5]) {
              m_ajutkoord = k.keko;
              v_ajutkoord = k.v_keko;
              m_ajutarr = [...minulaevadkeskel, ...minulaevadbaasis];
              v_ajutarr = [...vastaselaevadbaasis];
              //console.log("vastane tungib baasist välja, mina saan abivägesid");
            } else if (ting[3] && ting[4] && ting[5]) {
              m_ajutkoord = k.keko;
              v_ajutkoord = k.v_keko;
              m_ajutarr = [...minulaevadbaasis];
              v_ajutarr = [...vastaselaevadkeskel, ...vastaselaevadbaasis];
              //console.log("mina tungin baasist välja, vastane saab abivägesid");
            } else if (
              ting[1] === false &&
              ting[2] &&
              ting[4] === false &&
              ting[5]
            ) {
              if (ting[6]) {
                v_ajutkoord = k.v_keko;
                v_ajutarr = [...vastaselaevadbaasis];
                //console.log("vastane tungib baasist välja");
              }
              if (ting[6] === false) {
                m_ajutkoord = k.v_keko;
                v_ajutkoord = k.baasiryndevastu;
                m_ajutarr = [...minulaevadkeskel];
                v_ajutarr = [...vastaselaevadbaasis];
                //console.log("mina tungin baasi, vastane ründab vastu");
              }
            } else if (
              ting[0] === false &&
              ting[3] &&
              ting[4] &&
              ting[5] === false
            ) {
              if (ting[6]) {
                m_ajutkoord = k.keko;
                m_ajutarr = [...minulaevadbaasis];
                //console.log("mina tungin baasist välja");
              }
              if (ting[6] === false) {
                v_ajutkoord = k.keko;
                m_ajutkoord = k.v_baasiryndevastu;
                v_ajutarr = [...vastaselaevadkeskel];
                m_ajutarr = [...minulaevadbaasis];
                //console.log("vastane tungib minu baasi, mina ründan vastu");
              }
            } else if (ting[4] && ting[5] && ting[6]) {
              m_ajutkoord = k.keko;
              v_ajutkoord = k.v_keko;
              m_ajutarr = [...minulaevadbaasis];
              v_ajutarr = [...vastaselaevadbaasis];
              //console.log("mina ja vastane tungime mõlemad baasist välja ja saame keskel kokku");
            } else console.log("ükski variant polnud õige");
            if (lopp !== undefined) {
              m_loppkoord = lopp.mina === "mlb" ? k.lako : k.keko;
              v_loppkoord = lopp.vastane === "vlb" ? k.v_lako : k.v_keko;
            } else console.log("lopp on undefined");
          }
          tingid();
          for (let i in inikas) {
            laev = lookup(inikas[i].tyyp);
            algkoord = mismassiiv(inikas[i].id);
            if (laev.klass !== 0) {
              if (inikas[i].vastane) {
                if (
                  v_ajutarr.length > 0 &&
                  v_ajutarr.some((a) => a.id === inikas[i].id)
                ) {
                  vahepeatus = kasajutarr(inikas[i].id);
                } else {
                  vahepeatus = mismassiiv(inikas[i].id);
                }
                if (
                  v_lopparr.length > 0 &&
                  v_lopparr.some((a) => a.id === inikas[i].id) &&
                  lopp !== undefined
                ) {
                  lopppeatus = kaslopparr(inikas[i].id);
                } else {
                  lopppeatus = mismassiiv(inikas[i].id);
                }
                if (anima[1] > anima_kestus) {
                  pildisuund =
                    vahepeatus.x <= algkoord.x
                      ? laev.pilt * 100 + pildilisa
                      : laev.pilt * 100;
                } else {
                  pildisuund =
                    lopppeatus.x <= vahepeatus.x
                      ? laev.pilt * 100 + pildilisa
                      : laev.pilt * 100;
                }
              }
              if (inikas[i].vastane === false) {
                if (
                  m_ajutarr.length > 0 &&
                  m_ajutarr.some((a) => a.id === inikas[i].id)
                ) {
                  vahepeatus = kasajutarr(inikas[i].id);
                } else {
                  vahepeatus = mismassiiv(inikas[i].id);
                }
                if (
                  m_lopparr.length > 0 &&
                  m_lopparr.some((a) => a.id === inikas[i].id) &&
                  lopp !== undefined
                ) {
                  lopppeatus = kaslopparr(inikas[i].id);
                } else {
                  lopppeatus = mismassiiv(inikas[i].id);
                }
                if (anima[1] > anima_kestus) {
                  pildisuund =
                    vahepeatus.x >= algkoord.x
                      ? laev.pilt * 100
                      : laev.pilt * 100 + pildilisa;
                } else {
                  pildisuund =
                    lopppeatus.x >= vahepeatus.x
                      ? laev.pilt * 100
                      : laev.pilt * 100 + pildilisa;
                }
              }
              if (anima[0] - anima[1] < anima_kestus) {
                x =
                  vahepeatus.x > algkoord.x
                    ? algkoord.x +
                      Math.ceil(
                        (Math.abs(algkoord.x - vahepeatus.x) / tsykkel) * praegu
                      )
                    : algkoord.x -
                      Math.ceil(
                        (Math.abs(algkoord.x - vahepeatus.x) / tsykkel) * praegu
                      );
                y =
                  vahepeatus.y > algkoord.y
                    ? algkoord.y +
                      Math.ceil(
                        (Math.abs(algkoord.y - vahepeatus.y) / tsykkel) * praegu
                      )
                    : algkoord.y -
                      Math.ceil(
                        (Math.abs(algkoord.y - vahepeatus.y) / tsykkel) * praegu
                      );
              } else if (anima[1] <= anima_kestus) {
                x =
                  lopppeatus.x > vahepeatus.x
                    ? vahepeatus.x +
                      Math.ceil(
                        (Math.abs(lopppeatus.x - vahepeatus.x) / tsykkel) *
                          praegu2
                      )
                    : vahepeatus.x -
                      Math.ceil(
                        (Math.abs(lopppeatus.x - vahepeatus.x) / tsykkel) *
                          praegu2
                      );
                y =
                  lopppeatus.y > vahepeatus.y
                    ? vahepeatus.y +
                      Math.ceil(
                        (Math.abs(lopppeatus.y - vahepeatus.y) / tsykkel) *
                          praegu2
                      )
                    : vahepeatus.y -
                      Math.ceil(
                        (Math.abs(lopppeatus.y - vahepeatus.y) / tsykkel) *
                          praegu2
                      );
              } else {
                x = vahepeatus.x;
                y = vahepeatus.y;
              }
              if (
                inikas[i].puruks === undefined ||
                (inikas[i].puruks !== undefined && anima[1] > inikas[i].puruks)
              ) {
                //joonista, kui laev ei hävi või pole veel hävinud
                joonista(
                  spriteRef.current,
                  pildisuund,
                  0,
                  100,
                  100,
                  x,
                  y,
                  k.ruut,
                  k.ruut
                );
              }
            }
          }
          for (let i in voitlus) {
            let nyyd = (ajakava[i].algus - anima[1]) / intervall;
            if (anima[1] <= ajakava[i].algus && anima[1] > ajakava[i].lopp) {
              //vastane ründab
              if (voitlus[i].laskja.vastane) {
                if (voitlus[i].laskja.tyyp === 0) {
                  laskja = {
                    get x() {
                      return k.vastaseemalaevx;
                    },
                    get y() {
                      return k.emalaevy + k.emalaevakorgus / 2;
                    },
                  };
                } else if (v_ajutarr.length > 0) {
                  laskja = kasajutarr(voitlus[i].laskja.id);
                } else {
                  laskja = mismassiiv(voitlus[i].laskja.id);
                }
                if (voitlus[i].sihtmark.id === undefined) {
                  if (voitlus[i].sihtmark.nimi === "laevakilp") {
                    if (m_ajutarr.some((a) => a.tyyp === 9)) {
                      sihtmark = kasajutarr(
                        inikas.find((a) => a.vastane === false && a.tyyp === 9)
                          .id
                      );
                    } else
                      sihtmark = mismassiiv(
                        inikas.find((a) => a.vastane === false && a.tyyp === 9)
                          .id
                      );
                  } else if (voitlus[i].sihtmark.nimi === "baasikilp") {
                    sihtmark = mismassiiv(
                      inikas.find((a) => a.vastane === false && a.tyyp === 13)
                        .id
                    );
                  } else if (voitlus[i].sihtmark.tyyp === 0) {
                    sihtmark = {
                      get x() {
                        return k.minuemalaevx + k.emalaevalaius / 2;
                      },
                      get y() {
                        return k.emalaevy + k.emalaevakorgus / 2;
                      },
                    };
                  }
                } else if (m_ajutarr.length > 0) {
                  sihtmark = kasajutarr(voitlus[i].sihtmark.id);
                } else {
                  sihtmark = mismassiiv(voitlus[i].sihtmark.id);
                }
              }

              //mina ründan
              if (voitlus[i].laskja.vastane === false) {
                if (voitlus[i].laskja.tyyp === 0) {
                  laskja = {
                    get x() {
                      return k.minuemalaevx + k.emalaevalaius;
                    },
                    get y() {
                      return k.emalaevy + k.emalaevakorgus / 2;
                    },
                  };
                } else if (m_ajutarr.length > 0) {
                  laskja = kasajutarr(voitlus[i].laskja.id);
                } else {
                  laskja = mismassiiv(voitlus[i].laskja.id);
                }

                if (voitlus[i].sihtmark.id === undefined) {
                  if (voitlus[i].sihtmark.nimi === "laevakilp") {
                    if (v_ajutarr.some((a) => a.tyyp === 9)) {
                      sihtmark = kasajutarr(
                        inikas.find((a) => a.vastane && a.tyyp === 9).id
                      );
                    } else
                      sihtmark = mismassiiv(
                        inikas.find((a) => a.vastane && a.tyyp === 9).id
                      );
                  } else if (voitlus[i].sihtmark.nimi === "baasikilp") {
                    sihtmark = mismassiiv(
                      inikas.find((a) => a.vastane && a.tyyp === 13).id
                    );
                  } else if (voitlus[i].sihtmark.tyyp === 0) {
                    sihtmark = {
                      get x() {
                        return k.vastaseemalaevx + k.emalaevalaius / 2;
                      },
                      get y() {
                        return k.emalaevy + k.emalaevakorgus / 2;
                      },
                    };
                  }
                } else if (v_ajutarr.length > 0) {
                  sihtmark = kasajutarr(voitlus[i].sihtmark.id);
                } else {
                  sihtmark = mismassiiv(voitlus[i].sihtmark.id);
                }
              }

              //laser
              if (anima[1] > ajakava[i].algus - anima_kestus) {
                context.strokeStyle = "red";
                context.lineWidth = 4;
                context.beginPath();
                if (voitlus[i].laskja.vastane) {
                  context.moveTo(laskja.x, laskja.y + k.ruut / 2);
                } else {
                  context.moveTo(laskja.x + k.ruut, laskja.y + k.ruut / 2);
                }
                context.lineTo(
                  sihtmark.x + k.poolruut,
                  sihtmark.y + k.poolruut
                );
                context.stroke();

                if (ajakava[i].algus - anima[1] < anima_kestus / 2) {
                  nyyd = (ajakava[i].algus - anima[1]) / intervall;
                  context.globalAlpha = 0 + (1 / tsykkel) * 2 * nyyd;
                } else {
                  nyyd =
                    (ajakava[i].algus - anima_kestus / 2 - anima[1]) /
                    intervall;
                  context.globalAlpha = 1 - (1 / tsykkel) * 2 * nyyd;
                }

                context.beginPath();
                context.strokeStyle = "yellow";
                context.lineWidth = 2;
                if (voitlus[i].laskja.vastane) {
                  context.moveTo(laskja.x, laskja.y + k.ruut / 2);
                } else {
                  context.moveTo(laskja.x + k.ruut, laskja.y + k.ruut / 2);
                }
                context.lineTo(
                  sihtmark.x + k.poolruut,
                  sihtmark.y + k.poolruut
                );
                context.stroke();
                context.globalAlpha = 1;
              }

              //plahvatus
              if (
                anima[1] <= ajakava[i].algus - anima_kestus &&
                anima[1] > ajakava[i].algus - anima_kestus - ajakava[i].puru &&
                (voitlus[i].sihtmark.nimi === "laevakilp" ||
                  voitlus[i].sihtmark.nimi === "baasikilp")
              ) {
                plahvatus(
                  sihtmark.x + k.ruut / 2,
                  sihtmark.y + k.ruut / 2,
                  40,
                  ajakava[i].algus - anima_kestus,
                  ajakava[i].puru,
                  500,
                  true
                );
              } else if (voitlus[i].sihtmark.tyyp === 0 && voitlus[i].puru) {
                plahvatus(
                  sihtmark.x + k.ruut / 2,
                  sihtmark.y + k.ruut / 2,
                  200,
                  ajakava[i].algus - anima_kestus,
                  ajakava[i].puru
                );
                setTimeout(() => {
                  if (voitlus[i].laskja.vastane) {
                    nodraw.current = "minuemalaev";
                  } else {
                    nodraw.current = "vastaseemalaev";
                  }
                }, [ajakava[i].puru]);
              } else {
                plahvatus(
                  sihtmark.x + k.ruut / 2,
                  sihtmark.y + k.ruut / 2,
                  25,
                  ajakava[i].algus - anima_kestus,
                  ajakava[i].puru
                );
              }
            }
          }
        }
        if (rakettlendab.current) {
          const rale = rakettlendab.current;
          const rakett = lookup(rale.tyyp);
          let tsykkel = (anima_kestus * 1.5) / intervall;
          let purutsykkel = puru_kestus / intervall;
          let praegu = (anima[0] - anima[1]) / intervall;
          let nyyd;
          let x_alguspunkt = rale.vastane ? k.c_laius : 0 - k.ruut;
          let x;
          let y = k.c_korgus / 2 - k.poolruut;
          let pildilisa = 100;
          let sihtpunkt;
          let raadius;
          if (rale.laser) {
            if (rale.tyyp === 3) {
              sihtpunkt = {
                x: k.c_laius - k.emalaevalaius - k.ruut - k.ruut,
                y: k.c_korgus / 2,
              };
            } else {
              sihtpunkt = {
                x: k.v_lako[0].x - k.ruut,
                y: k.c_korgus / 2,
              };
            }

            if (anima[0] - anima[1] <= anima_kestus * 1.5) {
              x =
                sihtpunkt.x < x_alguspunkt
                  ? x_alguspunkt -
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    )
                  : x_alguspunkt +
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    );
              joonista(
                spriteRef.current,
                sihtpunkt.x > x_alguspunkt
                  ? rakett.pilt * 100
                  : rakett.pilt * 100 + pildilisa,
                0,
                100,
                100,
                x,
                y,
                k.ruut,
                k.ruut
              );
            }
            if (
              anima[0] - anima[1] >= anima_kestus &&
              anima[0] - anima[1] < anima_kestus * 1.5
            ) {
              context.beginPath();
              context.strokeStyle = "red";
              context.lineWidth = 4;
              const laskja = mismassiiv(
                vastasekaitse.find((a) => a.tyyp === 4).id
              );
              context.moveTo(laskja.x, laskja.y + k.ruut / 2);
              context.lineTo(x + k.ruut, y + k.poolruut);
              context.stroke();
            }

            plahvatus(
              sihtpunkt.x + k.ruut,
              sihtpunkt.y,
              25,
              anima[0] - anima_kestus * 1.5
            );
          } else if (rale.tyhja) {
            sihtpunkt = {
              x: k.c_laius,
              y: k.c_korgus / 2,
            };
            x =
              sihtpunkt.x < x_alguspunkt
                ? x_alguspunkt -
                  Math.ceil(
                    (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                  )
                : x_alguspunkt +
                  Math.ceil(
                    (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                  );
            joonista(
              spriteRef.current,
              sihtpunkt.x > x_alguspunkt
                ? rakett.pilt * 100
                : rakett.pilt * 100 + pildilisa,
              0,
              100,
              100,
              x,
              y,
              k.ruut,
              k.ruut
            );
          } else if (rale.tyyp === 3) {
            let puru = false;
            if (rale.vastane) {
              sihtpunkt = { x: k.emalaevalaius / 2, y: k.c_korgus / 2 };
              if (minuhp <= 3) {
                puru = true;
              }
            } else {
              sihtpunkt = {
                x: k.c_laius - k.emalaevalaius / 2,
                y: k.c_korgus / 2,
              };
              if (vastasehp <= 3) {
                puru = true;
              }
            }
            if (anima[0] - anima[1] <= anima_kestus * 1.5) {
              x =
                sihtpunkt.x < x_alguspunkt
                  ? x_alguspunkt -
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    )
                  : x_alguspunkt +
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    );
              joonista(
                spriteRef.current,
                sihtpunkt.x > x_alguspunkt
                  ? rakett.pilt * 100
                  : rakett.pilt * 100 + pildilisa,
                0,
                100,
                100,
                x,
                y,
                k.ruut,
                k.ruut
              );
            }
            if (rale.kilp === true) {
              let sihtmargid;
              let sihtmark;
              plahvatus(
                sihtpunkt.x + k.ruut / 2,
                sihtpunkt.y,
                100,
                anima[0] - anima_kestus * 1.5,
                1000,
                true
              );
              if (rale.vastane) {
                sihtmargid = minukaitse.filter((a) => a.tyyp === 13);
              } else {
                sihtmargid = vastasekaitse.filter((a) => a.tyyp === 13);
              }
              for (let i in sihtmargid) {
                sihtmark = mismassiiv(sihtmargid[i].id);
                plahvatus(
                  sihtmark.x + k.ruut / 2,
                  sihtmark.y + k.ruut / 2,
                  25,
                  anima[0] - anima_kestus * 1.8
                );
              }
            } else {
              if (puru) {
                plahvatus(
                  sihtpunkt.x + k.ruut / 2,
                  sihtpunkt.y,
                  200,
                  anima[0] - anima_kestus * 1.5,
                  3000
                );
                setTimeout(() => {
                  nodraw.current = rale.vastane
                    ? "minuemalaev"
                    : "vastaseemalaev";
                }, 3000);
              } else
                plahvatus(
                  sihtpunkt.x + k.ruut / 2,
                  sihtpunkt.y,
                  100,
                  anima[0] - anima_kestus * 1.5,
                  1000
                );
            }
          } else {
            //MITMIKRAKETI OSA
            tsykkel = anima_kestus / intervall;
            if (rale.vastane) {
              sihtpunkt = {
                x: mismassiiv(rale.sihtmargid[0].id).x + k.ruut * 2,
                y: k.c_korgus / 2,
              };
            } else {
              sihtpunkt = {
                x: mismassiiv(rale.sihtmargid[0].id).x - k.ruut * 2,
                y: k.c_korgus / 2,
              };
            }
            if (anima[0] - anima[1] < anima_kestus) {
              x =
                sihtpunkt.x < x_alguspunkt
                  ? x_alguspunkt -
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    )
                  : x_alguspunkt +
                    Math.ceil(
                      (Math.abs(x_alguspunkt - sihtpunkt.x) / tsykkel) * praegu
                    );
              joonista(
                spriteRef.current,
                sihtpunkt.x > x_alguspunkt
                  ? rakett.pilt * 100
                  : rakett.pilt * 100 + pildilisa,
                0,
                100,
                100,
                x,
                y,
                k.ruut,
                k.ruut
              );
            }
            plahvatus(
              sihtpunkt.x + k.ruut,
              sihtpunkt.y,
              10,
              anima[0] - anima_kestus * 0.9,
              700
            );
            for (let i in rale.sihtmargid) {
              const sihtmark = mismassiiv(rale.sihtmargid[i].id);
              const vahe = i * 200;
              laserkiir(
                sihtpunkt.x + k.ruut,
                sihtpunkt.y,
                sihtmark.x + k.poolruut,
                sihtmark.y + k.poolruut,
                anima[0] - anima_kestus * 1.1 - vahe,
                200
              );

              if (rale.sihtmargid[i].kilp) {
                if (i === 0) {
                  plahvatus(
                    sihtmark.x + k.poolruut,
                    sihtmark.y + k.poolruut,
                    20,
                    anima[0] - anima_kestus * 1.3 - vahe,
                    500,
                    true
                  );
                } else {
                  plahvatus(
                    sihtmark.x + k.poolruut,
                    sihtmark.y + k.poolruut,
                    40,
                    anima[0] - anima_kestus * 1.3 - vahe,
                    1000,
                    true
                  );
                }
              } else if (rale.sihtmargid[i].puru) {
                plahvatus(
                  sihtmark.x + k.poolruut,
                  sihtmark.y + k.poolruut,
                  30,
                  anima[0] - anima_kestus * 1.3 - vahe,
                  1000
                );
              }
            }
          }
        }
      } else {
        context.font = "150px Arial";
        context.fillStyle = "white";
        context.textAlign = "center";
        context.fillText(
          keel === "est" ? "MÄNG LÄBI" : "GAME OVER",
          k.c_laius / 2,
          (k.c_korgus / 3) * 2
        );
      }
    }
  }

  function muudamana(maha, vastane = false) {
    let mana = vastane ? vastasemana : minumana;
    mana += maha;
    vastane ? setVastasemana(mana) : setMinumana(mana);
  }

  function jagakaarte(m, v) {
    let pakk = [];
    let k2tte = [];
    let valitudkaart = null;
    let loendur = 0;
    if (m > 0 && minupakk.length > 0) {
      pakk = [...minupakk];
      k2tte = [...minukasi];
      loendur = minukaardiloendur;
      for (let i = m; i > 0; i--) {
        loendur++;
        setMinukaardiloendur(loendur);
        valitudkaart = suva(pakk.length);
        k2tte.push({ tyyp: pakk[valitudkaart], id: loendur });
        if (k2tte.length > 7) {
          k2tte.sort((a, b) => a.id - b.id);
          k2tte.splice(0, 1);
        }
        pakk.splice(valitudkaart, 1);
      }
      setMinupakk(pakk);
      setMinukasi(k2tte);
    }

    if (v > 0 && vastasepakk.length > 0) {
      pakk = [...vastasepakk];
      k2tte = [...vastasekasi];
      loendur = vastasekaardiloendur;
      for (let i = v; i > 0; i--) {
        loendur++;
        setVastasekaardiloendur(loendur);
        valitudkaart = suva(pakk.length);
        k2tte.push({ tyyp: pakk[valitudkaart], id: loendur });
        if (k2tte.length > 7) {
          k2tte.sort((a, b) => a.id - b.id);
          k2tte.splice(0, 1);
        }
        pakk.splice(valitudkaart, 1);
      }
      setVastasepakk(pakk);
      setVastasekasi(k2tte);
    }
  }

  function viskakaart(id, vastane = false) {
    let kasi = vastane ? [...vastasekasi] : [...minukasi];
    let prygi = vastane ? [...vastaseprygi] : [...minuprygi];
    let visatudkaart = kasi.splice(
      kasi.findIndex((a) => a.id === id),
      1
    )[0];
    if (visatudkaart !== undefined) {
      prygi.push(visatudkaart);
    }
    vastane ? setVastasekasi(kasi) : setMinukasi(kasi);
    vastane ? setVastaseprygi(prygi) : setMinuprygi(prygi);
  }

  function kaartlauda(kaart, vastane = false) {
    const kaardiinfo = lookup(kaart.tyyp);
    let uus = {};
    let uuslaevapakk = vastane
      ? [...vastaselaevadbaasis]
      : [...minulaevadbaasis];
    let uuskaitsepakk = vastane ? [...vastasekaitse] : [...minukaitse];

    let praeguneID = 0;
    if (kaardiinfo.klass === 4) {
      raketilask(kaart.tyyp, vastane ? true : false);
    }
    if (
      kaardiinfo.klass === 1 ||
      kaardiinfo.klass === 2 ||
      kaardiinfo.klass === 3 ||
      kaardiinfo.klass === 5
    ) {
      if (vastane) {
        praeguneID = vastaseyksuseloendur;
        praeguneID++;
        setVastaseyksuseloendur(praeguneID);
      } else {
        praeguneID = minuyksuseloendur;
        praeguneID++;
        setMinuyksuseloendur(praeguneID);
      }

      if (kaardiinfo.klass === 1 && uuskaitsepakk.length < 4) {
        uus = {
          tyyp: kaart.tyyp,
          id: praeguneID,
          hp: kaardiinfo.kaitse,
          vastane: vastane ? true : false,
        };
        uuskaitsepakk.push(uus);
      }
      if (
        (kaardiinfo.klass === 2 ||
          kaardiinfo.klass === 3 ||
          kaardiinfo.klass === 5) &&
        uuslaevapakk.length < 12
      ) {
        uus = {
          tyyp: kaart.tyyp,
          id: praeguneID,
          hp: kaardiinfo.kaitse,
          vastane: vastane ? true : false,
        };
        uuslaevapakk.push(uus);
      }
      if (vastane === false) {
        setMinukaitse(uuskaitsepakk);
        setMinulaevadbaasis(uuslaevapakk);
      }
      if (vastane) {
        setVastasekaitse(uuskaitsepakk);
        setVastaselaevadbaasis(uuslaevapakk);
      }
    }
    viskakaart(kaart.id, vastane ? true : false);
    muudamana(-lookup(kaart.tyyp).maks, vastane ? true : false);

    if (
      kaardiinfo.klass === 2 ||
      kaardiinfo.klass === 3 ||
      kaardiinfo.klass === 5
    ) {
      vastane
        ? setAnimakaib({
            m_laevad: [],
            v_laevad: [uus],
            m_alguspunkt: 0,
            v_alguspunkt: 0,
          })
        : setAnimakaib({
            m_laevad: [uus],
            v_laevad: [],
            m_alguspunkt: 0,
            v_alguspunkt: 0,
          });
    }
    if (kaardiinfo.klass === 1 && vastane) {
      setVastasekaardidkaidud(vastasekaardidkaidud + 1);
    }
  }

  function klikk_m2ngukaardil(kaart) {
    const tyyp = lookup(kaart.tyyp);
    if (vaba) {
      if (minumana >= tyyp.maks) {
        if (tyyp.klass === 2 || tyyp.klass === 3 || tyyp.klass === 5) {
          if (minulaevadbaasis.length + minulaevadkeskel.length < 12) {
            kaartlauda(kaart);
          } else console.log("sul on liiga palju laevu");
        }
        if (tyyp.klass === 1) {
          if (minukaitse.length < 4) {
            kaartlauda(kaart);
          } else console.log("sul on liiga palju kahureid");
        }
        if (tyyp.klass === 4) {
          kaartlauda(kaart);
        }
      } else {
        console.log(
          "kaarti " + tyyp.nimi + " ei saa käia, kuna sul pole piisavalt mana"
        );
      }
    }
  }

  useEffect(() => {
    if (lahinganima !== 0) {
      anima[0] = lahinganima;
      anima[1] = anima[0];
      console.log("lahingu animatsioon algab, kestus: " + lahinganima);
      alustaIntervall(lahingIntervall);
    }
  }, [lahinganima]);

  useEffect(() => {
    if (animakaib !== 0) {
      if (animakaib.m_laevad.length > 0 || animakaib.v_laevad.length > 0) {
        anima[0] = anima_kestus;
        anima[1] = anima[0];
        animeerida = { ...animakaib };
        alustaIntervall(liikumisIntervall);
        if (minukaik.current === true) {
          vaba = false;
          console.log("sinu laevaehituse animatsioon algas");
        } else if (vastasekaardikaimine.current === true) {
          console.log("vastase laevaehituse animatsioon algas");
        } else {
          console.log("käigulõpu animatsioon algas");
        }
      } else {
        if (minukaik.current === true) {
          vaba = true;
          console.log("sinu laevaehituse animatsioon lõppes");
        } else {
          if (vastasekaardikaimine.current === true) {
            console.log("vastase laevaehituse animatsioon lõppes");
            vastasekaardikaimine.current = false;
            setVastasekaardidkaidud(vastasekaardidkaidud + 1);
          } else {
            console.log("käigulõpu animatsioon lõppes");
            setLahingujargne(lahingujargne + 1);
          }
        }
      }
    }
  }, [animakaib]);

  useEffect(() => {
    if (lahingujargne > 0) {
      joonistamine();
      relsid();
    }
  }, [lahingujargne]);

  useEffect(() => {
    if (minukaikjalle > 0) {
      minukaik.current = true;
      vaba = true;
      joonistamine();
    }
  }, [minukaikjalle]);

  useEffect(() => {
    if (minukaiklabi > 0) {
      minukaik.current = false;
      vaba = false;
      ai();
    }
  }, [minukaiklabi]);

  useEffect(() => {
    if (vastasekaardidkaidud > 0) {
      ai(true);
    }
  }, [vastasekaardidkaidud]);

  useEffect(() => {
    if (relsidkainud > 0) {
      joonistamine();
      kaigulopp2();
    }
  }, [relsidkainud]);

  useEffect(() => {
    if (vastasekaiklabi > 0) {
      kaigulopp();
    }
  }, [vastasekaiklabi]);

  useEffect(() => {
    if (pildidlaetud) {
      if (minuhp < 1 || vastasehp < 1) {
        mangulopp();
      }
      joonistamine();
    }
  }, [
    minulaevadbaasis,
    minulaevadkeskel,
    minukaitse,
    vastaselaevadbaasis,
    vastaselaevadkeskel,
    vastasekaitse,
    minuhp,
    vastasehp,
  ]);

  useEffect(() => {
    if (pildidlaetud) {
      joonistamine();
      jagakaarte(4, 4);
    }
  }, [pildidlaetud]);

  useEffect(() => {
    let laetud = 0;
    emalaevRef.current = new Image();
    emalaevRef.current.onload = () => {
      laetud++;
      if (laetud === 3) {
        setPildidlaetud(true);
      }
    };
    emalaevRef.current.src = emalaev;
    spriteRef.current = new Image();
    spriteRef.current.onload = () => {
      laetud++;
      if (laetud === 3) {
        setPildidlaetud(true);
      }
    };
    spriteRef.current.src = spraidid;
    kosmosRef.current = new Image();
    kosmosRef.current.onload = () => {
      laetud++;
      if (laetud === 3) {
        setPildidlaetud(true);
      }
    };
    kosmosRef.current.src = kosmos;
  }, []);

  function liikumisIntervall() {
    if (anima[1] > 0) {
      anima[1] -= intervall;
      joonistamine();
    }
    if (anima[1] === 0) {
      anima[0] = 0;
      animeerida = {
        m_laevad: [],
        v_laevad: [],
        m_alguspunkt: 0,
        v_alguspunkt: 0,
      };
      setAnimakaib({
        m_laevad: [],
        v_laevad: [],
        m_alguspunkt: 0,
        v_alguspunkt: 0,
      });
      peataIntervall();
    }
  }

  function lahingIntervall() {
    if (anima[1] > 0) {
      anima[1] -= intervall;
      joonistamine();
    }
    if (anima[1] === 0) {
      anima[0] = 0;
      setLahinganima(0);
      console.log("lahingu animatsioonifaas lõppes");
      peataIntervall();
    }
  }

  function rakettIntervall() {
    if (anima[1] > 0) {
      anima[1] -= intervall;
      joonistamine();
    }
    if (anima[1] === 0) {
      anima[0] = 0;
      console.log("raketi animatsioon lõppes");
      peataIntervall();
    }
  }

  function alustaIntervall(alusta) {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(alusta, intervall);
  }

  function peataIntervall() {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  return (
    <div className="h-[93%] w-full border-t-2 flex flex-col">
      <div className="h-[60%] flex flex-col items-center justify-start border-b-2">
        praegune käik: {kaik}
        <div className="w-[960px] h-[400px]" style={{ marginTop: "1%" }}>
          <canvas
            id="canvas"
            width={k.c_laius}
            height={k.c_korgus}
            ref={canvasRef}
          ></canvas>
        </div>
        <div className="w-[960px] h-full flex">
          <div className="items-start w-1/2">
            emalaeva hp: {minuhp}
            <br />
            mana: {minumana}
          </div>
          <div className="items-end w-1/2"></div>
          {vastasehp}
          <br />
          {vastasemana}
        </div>
      </div>
      <div className="h-[200px] bg-valge rounded-b-lg flex">
        {minukasi.map((a, index) => (
          <Kaart
            key={`kaart-${a.tyyp}-${index}`}
            nimi={lookup(a.tyyp).nimi}
            /* pilt={lookup(a.tyyp).pilt} */
            maks={lookup(a.tyyp).maks}
            mana={minumana}
            onClick={() => klikk_m2ngukaardil(a)} //
          />
        ))}
      </div>
      <div className="w-full h-full">
        {/* <Testnupp nimi="Keskele" onClick={() => keskele()} />
        <Testnupp nimi="Tagasi" onClick={() => baasi_tagasi()} />
        <Testnupp nimi="Vaenlaseks" onClick={() => vaenlaseks()} />
        <Testnupp nimi="Omaks" onClick={() => omaks_tagasi()} />
        <Testnupp nimi="Baasi ette" onClick={() => baasiette()} />
        <Testnupp nimi="Teel kohtumine" onClick={() => teelkohtume()} /> 
        <br />*/}
        <Testnupp nimi="Taandu" onClick={() => taandu()} />
        <Testnupp nimi="Jää paigale" onClick={() => jaapaigale()} />
        <Testnupp nimi="Liigu edasi" onClick={() => liiguedasi()} />
        <Testnupp nimi="Rynda" onClick={() => rynda()} />
        {/* <Radiobutton
          pealkiri="Vastase valikud"
          nupud={nupuvalikud}
          nupuValik={nupuValik}
          nupuValikuMuutus={nupuValikuMuutus}
        /> */}
        <br />
        <Testnupp nimi="Anna mulle kaart" onClick={() => jagakaarte(1, 0)} />
        <Testnupp
          nimi="Anna vastasele kaart"
          onClick={() => jagakaarte(0, 1)}
        />
      </div>
    </div>
  );
}

export default Mang;
